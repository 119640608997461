import React, { useMemo } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { Icon } from "@iconify/react";

export const TextInput = React.forwardRef(
	({ label, value, onChange, onKeyDown, type, icon, disabled, required, name, pattern }, ref) => {
		const iconElement = useMemo(() => {
			if (icon) return icon;

			if (type === "search") return "material-symbols:search";
		}, [type, icon]);

		return (
			<TextField
				name={name}
				defaultValue={value}
				inputRef={ref}
				label={label}
				onChange={onChange}
				type={type}
				disabled={disabled}
				required={required}
				InputProps={{
					onKeyDown: onKeyDown,
					endAdornment: iconElement && (
						<InputAdornment position="end">
							<Icon icon={iconElement} />
						</InputAdornment>
					),
				}}
				inputProps={{
					pattern: pattern,
				}}
			/>
		);
	}
);

TextInput.defaultProps = {
	label: "",
	value: "",
	onChange: () => {},
	onKeyDown: () => {},
	type: "text",
	icon: null,
	disabled: false,
	required: false,
	name: "",
	pattern: undefined,
};
