import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { keys } from "../../../../locales/localeskeys";

import {
	addressSelector,
	setShippingAddress,
	deleteAddress,
} from "../../../../store/auth";

import { Button } from "../../../../atoms/button/Button";
import Card from "../../card/Card";

import classes from "./ShippingAddressList.module.scss";

export const ShippingAddressList = ({ classes }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const shippingAddress = useSelector(addressSelector);

	const [addressList, setAddressList] = useState([]);

	const navigate = useNavigate();

	const handleAddShippingAddress = () => {
		window.scrollTo(0, 0);
		dispatch(setShippingAddress({}));
		navigate("/account/shipping-address");
	};

	const handleOnEdit = (address) => {
		window.scrollTo(0, 0);
		dispatch(setShippingAddress(address));
		navigate("/account/shipping-address");
	};

	const handleOnRemove = (address) => {
		dispatch(deleteAddress(address.id));

		const updatedList = addressList.filter((addr) => addr.id !== address.id);
		setAddressList(sortAddresses(updatedList));
	};

	const sortAddresses = (addresses) => {
		return [...addresses].sort((a, b) => {
			if (a.is_default === b.is_default) {
				return a.name.localeCompare(b.name);
			}
			return a.is_default ? -1 : 1;
		});
	};

	useEffect(() => {
		if (shippingAddress) {
			setAddressList(sortAddresses(shippingAddress));
			window.scrollTo(0, 0);
		}
	}, [shippingAddress]);

	return (
		<div className={classes.frameset}>
			<div className={classes.header}>
				<Button onClick={handleAddShippingAddress} icon="material-symbols:add">
					{t(keys.ACCOUNT.ADDRESS.ADD_BUTTON_LABEL)}
				</Button>
			</div>
			<div className={classes.content}>
				{addressList &&
					addressList.map((data, key) => (
						<Card
							key={key}
							title={data.name}
							isDefault={data.is_default}
							onEdit={() => handleOnEdit(data)}
							onRemove={() => handleOnRemove(data)}
							showSetByDefault={false}
						>
							<div>{data.line1}</div>
							<div>{`${data.city}, ${data.state}, ${data.postal_code}, ${data.country}`}</div>
						</Card>
					))}
			</div>
		</div>
	);
};

ShippingAddressList.defaultProps = {
	classes,
};
