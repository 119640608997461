import React from "react";
import LinkTree from "../../components/ui/LinkTree/LinkTree";
import classes from "./HeaderPage.module.scss";

export const HeaderPage = ({ classes, tree, title }) => {
	return (
		<header className={classes.header}>
			<LinkTree tree={tree} />
			<div className={classes.title}>{title}</div>
		</header>
	);
};

HeaderPage.defaultProps = {
	classes,
	tree: [],
	title: "",
};