import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Autocomplete } from "@material-ui/lab";
import clsx from "clsx";

import { keys } from "../../../../locales/localeskeys";

import { paymentMethodSelector, setPaymentMethod } from "../../../../store/paymentMethod";

import { Button } from "../../../../atoms/button/Button";
import { CheckboxInput, TextInput } from "../../../../atoms/forms";
import {
	TextField,
} from "@material-ui/core";

import classes from "./PaymentMethodsForm.module.scss";

export const PaymentMethodsForm = ({ classes }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const paymentMethod = useSelector(paymentMethodSelector);

	const navigate = useNavigate();

    const countries = [
        { name: 'Canada'},
        { name: 'United States'},
    ];

    const states = [
        { name: 'Quebec'},
    ]

	const handleIsDefault = () => {
		console.log("xxx");
	};

	const handleOnSave = () => {
		console.log("xxx");
	};

	const handleOnCancel = () => {
		dispatch(setPaymentMethod({}));
		navigate("/account/payment-methods");
	};

	return (
		<div className={classes.frameset}>
			<Alert severity="info">{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.ALERT)}</Alert>
			<div className={classes.section}>
				<div className={clsx(classes.bold, classes.article)}>
					{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARD_INFORMATION)}
				</div>
				<div className={classes.article}>
					<div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARD_NUMBER)}
							value={paymentMethod.ending_card ? `**** **** **** ${paymentMethod.ending_card}` : ""}
							disabled={paymentMethod.ending_card}
							required={true}
						/>
					</div>
					{/* <div>Franchises</div> */}
				</div>
				<div className={classes.article}>
					<div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARDHOLDER)}
							value={paymentMethod.cardholder || ""}
							required={true}
						/>
					</div>
					<div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.EXPIRATION_DATE)}
							value={paymentMethod.expires_at || ""}
							required={true}
						/>
					</div>
					<div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CVV)}
							disabled={paymentMethod.ending_card}
							required={true}
						/>
					</div>
				</div>
				<div className={classes.article}>
					<CheckboxInput
						label={t(keys.ACCOUNT.CARD.SET_BY_DEFAULT)}
						value={true}
						checked={paymentMethod.is_default}
						onChange={handleIsDefault}
					/>
				</div>
			</div>
			<div className={classes.section}>
				<div className={clsx(classes.bold, classes.article)}>
					{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.BILLING_INFORMATION)}
				</div>
				<div className={classes.article}>
					<div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.STREET_ADDRESS)}
							value={paymentMethod.street_address || ""}
							required={true}
						/>
					</div>
					<div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.ZIP_CODE)}
							value={paymentMethod.zip_code || ""}
							required={true}
						/>
					</div>
				</div>
				<div className={classes.article}>
					<div className={classes.item}>
                        <Autocomplete
							options={countries}
							getOptionLabel={(option) => option.name}
                            value={paymentMethod.country || ""}
                            inputValue={paymentMethod.country || ""}
							renderInput={(params) => <TextField {...params} label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.COUNTRY)} required={true} />}
						/>
					</div>
                    <div className={classes.item}>
						<Autocomplete
							options={states}
							getOptionLabel={(option) => option.name}
                            value={paymentMethod.state || ""}
                            inputValue={paymentMethod.state || ""}
							renderInput={(params) => <TextField {...params} label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.STATE)} required={true} />}
						/>
					</div>
                    <div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CITY)}
							value={paymentMethod.city || ""}
							required={true}
						/>
					</div>
				</div>
				<div className={classes.article}>
                    <div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.UNIT)}
							value={paymentMethod.unit || ""}
						/>
					</div>
                    <div className={classes.item}>
						<TextInput
							label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.PHONE_NUMBER)}
							value={paymentMethod.phone_number || ""}
                            required={true}
						/>
					</div>
				</div>
			</div>
			<div className={classes.footer}>
				<Button onClick={handleOnSave}>{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.SAVE)}</Button>
				<Button color="outlined" onClick={handleOnCancel}>
					{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CANCEL)}
				</Button>
			</div>
		</div>
	);
};

PaymentMethodsForm.defaultProps = {
	classes,
};
