import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

const mockData = [
	{ 
        id: 1, 
        label: "ScotiaCard with Visa Debit", 
        card_type: "CREDITCARD", 
        is_default: true, 
        ending_card: "1234",
        expires_at: "2025-03-01",
        cardholder: "Jhon Doe",
        street_address: "2685 Diab St",
        zip_code: "H4S 1E7",
        country: "Canada",
        state: "Quebec",
        city: "Saint-Laurent",
        unit: null,
        phone_number: "+1 555 555 5555",
    },
    { 
        id: 2, 
        label: "RBC with Visa Credit", 
        card_type: "CREDITCARD", 
        is_default: false, 
        ending_card: "0987",
        expires_at: "2024-12-01",
		cardholder: "Jhon Doe",
		street_address: "2685 Diab St",
        zip_code: "H4S 1E7",
        country: "Canada",
        state: "Quebec",
        city: "Saint-Laurent",
        unit: null,
        phone_number: "+1 555 555 5555",
    },
    { 
        id: 3, 
        label: "ScotiaCard with Master Card Debit", 
        card_type: "DEBITCARD", 
        is_default: false, 
        ending_card: "8888",
        expires_at: "2025-06-01",
		cardholder: "Jhon Doe",
		street_address: "2685 Diab St",
        zip_code: "H4S 1E7",
        country: "Canada",
        state: "Quebec",
        city: "Saint-Laurent",
        unit: null,
        phone_number: "+1 555 555 5555",
    },
];

export const getPaymentMethods = createAsyncThunk("paymentMethod/getPaymentMethods", async () => {
	return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ result: mockData });
		}, 500);
	});
});

const paymentMethodsAdapter = createEntityAdapter({});

const paymentMethods = createSlice({
	name: "paymentMethods",
	initialState: paymentMethodsAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
		paymentMethod: {}
	}),
	reducers: {
        setPaymentMethod(state, action) {
			state.paymentMethod = action.payload
		},
    },
	extraReducers: {
		[getPaymentMethods.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				paymentMethodsAdapter.removeAll(state);
				paymentMethodsAdapter.addMany(state, payload && payload.result ? payload.result : []);
			}
			return state;
		},
		[getPaymentMethods.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;

			return state;
		},
		[getPaymentMethods.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export const { setPaymentMethod } = paymentMethods.actions;
export default paymentMethods;

const paymentMethodSelectors = paymentMethodsAdapter.getSelectors((state) => state.paymentMethods);

export const paymentMethodsSelector = createSelector(paymentMethodSelectors.selectAll, (paymentMethod) => paymentMethod);
export const paymentMethodSelector = (state) => state?.paymentMethods?.paymentMethod;
// export const isLoadingSelector = (state) => state?.paymentMethod?.loading === "pending";
