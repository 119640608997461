import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import { getItemOverview, itemIsLoadingSelector, itemOverView } from "../../store/product";
import { clear } from "../../store/variant";

import ColorCard from "../products/variant/variantGrid/colorLigne/colorCard/ColorCard";
import ProductInfo from "../products/ProductOverview/ProductInfo/ProductInfo";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";
import Loading from "../ui/loading/Loading";
import ModalOrderColor from "./ModalOrderColor";

import { keys } from "../../locales/localeskeys";
import AdtoolsLogo from "../../assets/svg/adtools-desktop.svg";

import classes from "./TemplatePartner.module.scss";
import useElementSize from "../../hooks/useElementSize";
import logo from "../../assets/svg/adtools-logo.svg";
import colorMatching from "../../assets/svg/color-matching.svg";
import productCompatibility from "../../assets/svg/product-compatibility.svg";
import productSelector from "../../assets/svg/product-selector.svg";
import calculatorIcon from "../../assets/png/Calculator_icon.png";
import Ceraclad from "./Ceraclad/Ceraclad";

const TemplatePartner = (props) => {
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const lngId = i18n.language;
	const [gridRef, { width: gridWidth, height: heightWidth }] = useElementSize();
	const search = `?lng=${lngId}`;

	const itemsLoading = useSelector(itemIsLoadingSelector);
	const item = useSelector(itemOverView);

	const [isOpen, setOpen] = useState(false);
	const [showFullDescription, setShowFullDescription] = useState(false);

	useEffect(() => {
		dispatch(clear());
		dispatch(getItemOverview({ itemId: props.itemId, brandId: props.brandId }));
	}, []);
	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};
	const toggleDescription = () => {
		setShowFullDescription(!showFullDescription);
	};

	return (
		<div ref={gridRef}>
			<ModalOrderColor isOpen={isOpen} action={props.action} onClose={closeModal} />
			<div className={classes.imagLayout}>
				<img
					src={lngId.includes("fr") ? props.bannerFr : props.bannerEn}
					alt={lngId.includes("fr") ? props.bannerFr : props.bannerEn}
				/>
			</div>
			<h1 className={classes.title}>{t(keys.PARTNERS.TITLE)}</h1>
			<div className={classes.layout}>
				<p className={classes.description}>
					{showFullDescription ? (
						<>
							{props.description}
							{props.subDescription && <span>{props.subDescription}</span>}
						</>
					) : (
						<>
							{props.description}
							{gridWidth <= 833 && (
								<span className={classes.more} onClick={toggleDescription}>
									{t(keys.PARTNERS.MORE)}
								</span>
							)}
						</>
					)}
				</p>
				<div className={classes.produit}>
					{item && !itemsLoading ? (
						<ProductInfo
							outsidePage
							garanti={props.Ceraclad ? false : true}
							className={classes.customPadding}
						/>
					) : (
						<Loading className={classes.loading} type={"place"} />
					)}
				</div>

				<h1 className={classes.titleColor}>{props.titleColor}</h1>
				{props.Ceraclad
					? props.sections.map((section, index) => (
							<div key={index}>
								<h1 className={classes.descriptionColor}>{section.title}</h1>
								<hr />
								{section.sectionColors.map((sectionColor, indexsc) => (
									<div key={indexsc}>
										<h2 className={classes.sectionColorTitle}>{sectionColor.title}</h2>
										<div className={classes.CardContainer}>
											{sectionColor.colors.map((specification) => (
												<ColorCard
													key={specification.id}
													id={specification.id}
													title={specification.title}
													image={specification.image}
													hex={specification.hex}
													subtitle={
														lngId.includes("fr")
															? specification.subtitle_fr
															: specification.subtitle_en
													}
													description_format_fr={specification.description_format_fr}
													description_format_en={specification.description_format_en}
													displayImage={!!specification.image}
													itemid={props.itemId}
													brandid={props.brandId}
													specificationid=""
													className={props.style}
													PartnerCall={true}
												/>
											))}
										</div>
									</div>
								))}
							</div>
					  ))
					: props.sections.map((section, index) => (
							<div key={index}>
								<h1 className={classes.descriptionColor}>{section.title}</h1>
								<hr />
								<div className={classes.CardContainer}>
									{section.colors.map((specification) => (
										<ColorCard
											key={specification.id}
											id={specification.id}
											title={specification.title}
											image={specification.image}
											hex={specification.hex}
											subtitle={
												lngId.includes("fr")
													? specification.subtitle_fr
													: specification.subtitle_en
											}
											description_format_fr={specification.description_format_fr}
											description_format_en={specification.description_format_en}
											displayImage={!!specification.image}
											itemid={props.itemId}
											brandid={props.brandId}
											specificationid=""
											className={props.style}
											PartnerCall={true}
										/>
									))}
								</div>
							</div>
					  ))}

				<div className={classes.orderColorLayout}>
					<img src={props.colorChart} alt="adtool-logo.svg" />
					<div className={classes.left}>
						{gridWidth <= 833 ? (
							<p>{props.orderColorMobileDescription}</p>
						) : (
							<p>{props.orderColorDescription}</p>
						)}
						<div className={classes.documentsButton} onClick={openModal}>
							{gridWidth > 833 ? (
								<>
									<p>{props.orderColorButton}</p>
								</>
							) : (
								<p>{t(keys.PARTNERS.ORDER)}</p>
							)}
						</div>
					</div>
				</div>
				<div className={classes.digitalColorLayout}>
					<img src={props.DigitalChart} alt="adtool-logo.svg" />
					<div className={classes.left}>
						{gridWidth <= 833 && <p>{props.digitalColorDescription}</p>}
						<div
							className={classes.documentsButton}
							onClick={() =>
								window.open(lngId.includes("fr") ? props.ChartUrlFr : props.ChartUrlEn, "_blank")
							}
						>
							{gridWidth > 833 ? (
								<>
									<p>{props.digitalColorDescription}</p>
									<Icon className={classes.iconDownload} icon="icomoon-free:file-pdf" />
								</>
							) : (
								<p>{t(keys.PARTNERS.DOWNLOAD)}</p>
							)}
						</div>
					</div>
				</div>
				<div className={classes.addToolsLayout}>
					<img src={AdtoolsLogo} alt="adtool-logo.svg" />
					<div className={classes.left}>
						<h1>{t(keys.HOME.PRODUCT_NEED)}</h1>
						<h1>{`${t(keys.HOME.HOW_MANY)} ${t(keys.HOME.WHAT_COLOR)}`}</h1>
						<p>{t(keys.HOME.ADTOOLS_CAPTION)}</p>
						<CustomNavLink
							className={classes.btn}
							to="https://adfastcorp.com/outils-numeriques/"
							target="_blank"
						>
							<Icon className={classes.icon} icon="bi:arrow-right-square-fill" />
						</CustomNavLink>
					</div>
				</div>
				<div className={classes.links}>
					<div className={classes.firstLine}>
						<CustomNavLink
							className={classes.element}
							to={`/tools/find-the-right-sealant/${search}`}
							target={"_blank"}
						>
							<div className={classes.text}>
								<p>color matching</p>
							</div>
							<img src={colorMatching} alt="tube.svg" />
						</CustomNavLink>
						<CustomNavLink
							className={classes.element}
							to={
								lngId.includes("en")
									? "https://adfastcorp.com/en/silicone-compatibility/"
									: "https://adfastcorp.com/compatibilite-silicones/"
							}
							target={"_blank"}
						>
							<div className={classes.text}>
								<p>{t(keys.TOOLS.SEALANTCALCULATOR.PRODUCT_COMPATIBILITY)}</p>
							</div>
							<img src={productCompatibility} alt="tube.svg" />
						</CustomNavLink>
					</div>
					<div className={classes.secondLine}>
						<CustomNavLink
							className={classes.element}
							to={
								lngId.includes("en")
									? "https://adfastcorp.com/en/guided-product-selector/"
									: "https://adfastcorp.com/product-finder/"
							}
							target={"_blank"}
						>
							<div className={classes.text}>
								<p>{t(keys.TOOLS.SEALANTCALCULATOR.PRODUCT_SELECTOR)}</p>
							</div>
							<img className={classes.imgSelector} src={productSelector} alt="tube.svg" />
						</CustomNavLink>
						<CustomNavLink className={classes.element} to={"/tools/sealant-calculator/"} target={"_self"}>
							<div className={classes.text}>
								<p>{t(keys.TOOLS.SEALANTCALCULATOR.SEALANT_CALCULATOR)}</p>
							</div>
							<img src={calculatorIcon} alt="tube.svg" />
						</CustomNavLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplatePartner;
