import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { keys } from "../../locales/localeskeys";

import { HeaderPage } from "../../commons";
import Sidebar from "./sidebar/Sidebar";
import Routing from "./Routing";

import classes from "./Account.module.scss";


import { isLoggedInSelector, setPrecedentUrl } from "../../store/auth";


const Account = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoggedIn = useSelector(isLoggedInSelector);
	
	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	return (
		<section className="pages-layout">
			<HeaderPage
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: `/?lng=${lngId}` },
					{ label: t(keys.ACCOUNT.ACCOUNT.SETTINGS), to: `/account?lng=${lngId}` },
				]}
				title={t(keys.ACCOUNT.ACCOUNT.SETTINGS)}
			/>
			<main className={classes.main}>
				<Sidebar />
				<article className={classes.content}>
					<Routing />
				</article>
			</main>
		</section>
	);
};

Account.defaultProps = {
	classes,
};

export default Account;
