import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Greenguard.module.scss";
import { keys } from "../../locales/localeskeys";
import LinkTree from "../ui/LinkTree/LinkTree";
import ProductCard from "../products/ProductList/ProductGrid/ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import GreenguardCategories from "./Category/GreenguardCategories";
import Button from "../ui/Button/Button";
import {
	getGreenguardCategories,
	greenguardCategoriesLoadingSelector,
	greenguardCategoriesSelector,
} from "../../store/greenguardCategory";
import { clearAll, setPage } from "../../store/productQuery";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../ui/loading/Loading";
import { constants, constants as constant } from "../../locales/constant";
import { useLocalized } from "../../hooks";
import { getItemCarts, itemCartsSelector, itemHasErrorSelector, itemIsLoadingSelector } from "../../store/product";
import { GreenguardHeaderSelector, GreenguardLogoSelector } from "../../store/mediaBase";
import HTMLString from "react-html-string";

const Greenguard = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const layoutRef = useRef();
	const search = `?lng=${lngId}`;

	const { getLocalizedValueByKeyType, getLocalizedValueByCustomMapping } = useLocalized(lngId);

	const dispatch = useDispatch();

	const [searchParams, setSearchParams] = useSearchParams();

	const categoriesLoading = useSelector(greenguardCategoriesLoadingSelector);
	const greenguardHeader = useSelector(GreenguardHeaderSelector);
	const greenguardLogo = useSelector(GreenguardLogoSelector);
	const categorySelector = useSelector(greenguardCategoriesSelector);
	const items = useSelector(itemCartsSelector);
	const itemsLoading = useSelector(itemIsLoadingSelector);
	const itemHasError = useSelector(itemHasErrorSelector);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const pageQuery = queryParams.get("page");
	const categorySearchParm = searchParams.get("categories") ?? "";

	const [pageSize, setPageSize] = useState(0);
	const [pageLoading, setPageLoading] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(getGreenguardCategories());
		dispatch(
			getItemCarts({
				pageSize: pageSize,
				greendguard: true,
			})
		);
	}, []);

	useEffect(() => {
		dispatch(setPage(parseInt(pageQuery)));
	}, [pageQuery]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setPageLoading(false);
		}, 100);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (layoutRef.current) {
			setPageSize(layoutRef.current.offsetWidth >= 961 && layoutRef.current.offsetWidth <= 1249 ? 12 : 16);
		}
	}, [layoutRef.current, layoutRef.current?.offsetWidth]);
	useEffect(() => {
		if (pageLoading === false) {
			if (itemHasError) navigate({ pathname: `/` });
			dispatch(
				getItemCarts({
					pageSize: pageSize,
					greendguard: true,
				})
			);
		}
	}, [pageLoading, categorySearchParm]);

	const clear = () => {
		dispatch(clearAll());
		searchParams.delete("categories");
		searchParams.delete("page");
		setSearchParams(searchParams);
	};

	return (
		<>
			<div className={classes.tree}>
				<LinkTree
					tree={[
						{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
						{ label: t(keys.GREENGUARD.SECTION.PAGE), to: `/greenguard/${search}` },
					]}
				/>
			</div>
			{greenguardHeader && (
				<div className={classes.imagLayout}>
					<img src={lngId.includes("fr") ? greenguardHeader[0]?.img_fr : greenguardHeader[0]?.img_en} />
				</div>
			)}
			<div className={classes.layout}>
				{!greenguardHeader || (!greenguardLogo && <Loading type={"bounce"} />)}
				{greenguardHeader && greenguardLogo && (
					<div className={classes.descriptionSec}>
						<div className={classes.imageLogo}>
							<img src={lngId.includes("fr") ? greenguardLogo[0]?.img_fr : greenguardLogo[0]?.img_en} />
						</div>
						<div className={classes.content}>
							<HTMLString
								html={
									lngId.includes("fr")
										? greenguardHeader[0]?.description_fr.toString()
										: greenguardHeader[0]?.description_en.toString()
								}
							/>
						</div>
					</div>
				)}
				<div className={classes.productContent}>
					<div className={classes.filterSection}>
						<div className={classes.sidebarContainer}>
							<Button
								className={classes.btn}
								content={classes.textStyle}
								color={"outlineBlue"}
								disabled={itemsLoading}
								onClick={clear}
							>
								{t(keys.PRODUCTS.RESET_FILTERS)}
							</Button>
						</div>
						{!categoriesLoading && categorySelector.length > 0 && <GreenguardCategories />}
					</div>
					<div className={classes.productSection}>
						{" "}
						<div className={classes.sectionContainer} ref={layoutRef}>
							{itemsLoading && <Loading type={"bounce"} />}
							{!itemsLoading && (
								<div className={classes.gridContainer}>
									{items.map((item) => (
										<ProductCard
											id={item.id}
											itemId={item.itemId}
											itemErpCode={item.erp_code}
											brandId={item.brandId}
											brandErpCode={item.brandErpCode}
											subBrandCode={item.subBrandCode}
											subBrandCode_fr={item.subBrandCode_fr}
											key={item.id}
											defaultImage={item.defaultImage ? item.defaultImage : constant.DEFAULT_IMG}
											title={item.title}
											description={getLocalizedValueByKeyType(item, "description")}
											ProductHeaderLabel={getLocalizedValueByKeyType(item, "label")}
											formats={getLocalizedValueByCustomMapping(item, {
												en_us: "formats_en_us",
												default: "formats",
											})}
											searched_specification={item.searched_specification}
											disableFavorit
											viewCertificatEn={item?.greengrDocumentEN}
											viewCertificatFr={item?.greengrDocumentFR}
										/>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Greenguard;
