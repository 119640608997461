import React from "react";
import { Route, Routes } from "react-router-dom";

import { Profile } from "./profile/Profile";
import { PaymentMethodsList, PaymentMethodsForm } from "./paymentMethods";
import { ShippingAddressList, ShippingAddressForm } from "./shippingAddress";

const Routing = () => {
	return (
		<Routes>
			<Route path="/" exact element={<Profile />} />
			<Route path="/payment-methods" exact element={<PaymentMethodsList />} />
			<Route path="/payment-method" exact element={<PaymentMethodsForm />} />
			<Route path="/shipping-addresses" exact element={<ShippingAddressList />} />
			<Route path="/shipping-address" exact element={<ShippingAddressForm />} />
		</Routes>
	);
};

export default Routing;
