import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as ButtonMui } from "@material-ui/core";
import { Icon } from "@iconify/react";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		borderRadius: 0,
		boxShadow: "none",
		textTransform: "none",
		fontSize: 16,
		minWidth: 157,
		border: "1px solid",
		lineHeight: 1.5,
		textWrap: "nowrap",
		"&:focus": {
			boxShadow: "none",
		},
	},
	default: {
		backgroundColor: "#007AFF",
		color: "#FFFFFF",
		borderColor: "#085CC5",
		"&:hover, &:active": {
			backgroundColor: "#085CC5",
			borderColor: "#0D509B",
		},
	},
	outlined: {
		backgroundColor: "#FFFFFF",
		color: "#007AFF",
		borderColor: "#007AFF",
		"&:hover, &:active": {
			backgroundColor: "#FFFFFF",
			color: "#085CC5",
			borderColor: "#0D509B",
		},
	},
	negative: {
		backgroundColor: "#FFFFFF",
		color: "#007AFF",
		borderColor: "#999999",
		"&:hover, &:active": {
			backgroundColor: "#F4F4F4",
			borderColor: "#F4F4F4",
		},
	},
	danger: {
		backgroundColor: "#FF4D4D",
		color: "#FFCCCC",
		borderColor: "#CC3E3E",
		"&:hover, &:active": {
			backgroundColor: "#CC3E3E",
			borderColor: "#CC3E3E",
		},
	},
});

export const Button = ({ children, color, icon, onClick, disabled, type }) => {
	const classes = useStyles();

	return (
		<ButtonMui
			variant="contained"
			className={clsx(classes.root, {
				[classes.outlined]: color === "outlined",
				[classes.negative]: color === "negative",
				[classes.danger]: color === "danger",
				[classes.default]: !color || color === "default",
			})}
			startIcon={icon && <Icon icon={icon} />}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{children}
		</ButtonMui>
	);
};

Button.defaultProps = {
	color: "default",
	icon: null,
	onClick: () => {},
	disabled: false,
	type: null
};
