import React from "react";
import { useTranslation } from "react-i18next";

import { keys } from "../../../locales/localeskeys";

import { RadioInput } from "../../../atoms/forms";

import classes from "./Card.module.scss";

const Card = React.forwardRef(
	({ classes, id, title, isDefault, children, onChange, onEdit, onRemove, showSetByDefault }, key) => {
		const { t } = useTranslation();

		return (
			<div className={classes.card}>
				<div className={classes.content}>
					<div className={classes.title}>{title}</div>
					<div>{children}</div>
				</div>
				<div className={classes.footer}>
					{showSetByDefault && (
						<div>
							<RadioInput
								key={key}
								id={`isDefault_${key}`}
								label={t(keys.ACCOUNT.CARD.SET_BY_DEFAULT)}
								value={id}
								checked={isDefault}
								onChange={onChange}
							/>
						</div>
					)}
					<div className={classes.options}>
						{!isDefault && (
							<>
								<span className={classes.textlink} onClick={onEdit}>
									{t(keys.ACCOUNT.CARD.EDIT)}
								</span>
								<span className={classes.textlinkerror} onClick={onRemove}>
									{t(keys.ACCOUNT.CARD.REMOVE)}
								</span>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
);

Card.defaultProps = {
	classes,
};

export default Card;
