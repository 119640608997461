import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { keys } from "../../../locales/localeskeys";

import { customerCodeSelector, customerNameSelector, emailSelector, forgotPassword } from "../../../store/auth";

import { Button } from "../../../atoms/button/Button";

import classes from "./Profile.module.scss";

export const Profile = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const [requestSent, setRequestSent] = useState(false);

	const customerCode = useSelector(customerCodeSelector);
	const customerName = useSelector(customerNameSelector);
	const customerEmail = useSelector(emailSelector);

	const handleResetPassword = () => {
		if (requestSent === false) {
			dispatch(forgotPassword({ email: customerEmail, lngId: lngId }));
			setRequestSent(true);
		}
	};

	return (
		<div className={classes.frameset}>
			<div>
				<div className="caption">{t(keys.ACCOUNT.ACCOUNT.HOLDER)}</div>
				<div className={classes.bold}>{customerCode}</div>
			</div>

			<div>
				<div className="caption">{t(keys.ACCOUNT.ACCOUNT.NAME)}</div>
				<div className={classes.bold}>{customerName}</div>
			</div>

			<div>
				<div className="caption">{t(keys.AUTH.SIGN_UP.EMAIL)}</div>
				<div className={classes.bold}>{customerEmail}</div>
			</div>

			<div>
				<div className="caption">Password:</div>
				<div>
					<Button color="danger" onClick={handleResetPassword} disabled={requestSent}>
						{requestSent === false
							? t(keys.ACCOUNT.PROFILE.RESETPASSWORD)
							: t(keys.ACCOUNT.PROFILE.REQUESTSENT)}
					</Button>
				</div>
			</div>
		</div>
	);
};

Profile.defaultProps = {
	classes,
};
