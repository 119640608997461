import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";
import encodeSlash from "../../../../hooks/encodeSlash";
import decodeSlash from "../../../../hooks/decodeSlash";
import { useLocalized } from "../../../../hooks";
import useElementSize from "../../../../hooks/useElementSize";
import Dropdown from "../../../ui/Input/Dropdown/Dropdown";

import TextInput from "../../../ui/Input/TextInput/TextInput";
import Button from "../../../ui/Button/Button";
import CustomNavLink from "../../../ui/customNavLink/CustomNavLink";
import SealantCalculatorModal from "../SealantCalculator/SealantCalculatorModal";

import { currencySelector, isLoggedInSelector, quoteRequest, setPrecedentUrl } from "../../../../store/auth";
import { addLine, isLoadingSelector, orderLinesSelector, updateLine } from "../../../../store/cart";
import { setBrand, setFavorite, setSearch, setSort } from "../../../../store/variantQuery";
import {
	isSealantSelector,
	itemIsLoadingSelector,
	itemOverView,
	itemTechnicalOverview,
} from "../../../../store/product";
import {
	displayImageSelector,
	formatsSelector,
	getVariants,
	isVariantLoadedSelector,
	specificationsSelector,
	variantFormats,
	variantIsLoadingSelector,
} from "../../../../store/variant";

import tds_svg from "../../../../assets/svg/TDS.svg";
import sds_svg from "../../../../assets/svg/SDS.svg";
import fts_svg from "../../../../assets/svg/FTS.svg";
import fds_svg from "../../../../assets/svg/FDS.svg";
import pdf from "../../../../assets/svg/PDF.svg";
import colorMatching from "../../../../assets/svg/color-matching.svg";

import { keys } from "../../../../locales/localeskeys";
import { constants as constant } from "../../../../locales/constant";

import classes from "./ProductInfo.module.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import ReactPlayer from "react-player";

const ProductInfo = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const [gridRef, { width: gridWidth }] = useElementSize();
	const { getLocalizedValueByCustomMapping } = useLocalized(lngId);
	const search = `?lng=${lngId}`;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [videoRef, { width: videoRefWidth, height: videoRefHeight }] = useElementSize();
	const [SelectedvideoRef, { width: SelectedvideoRefWidth, height: SelectedvideoRefHeight }] = useElementSize();
	const [SelectedvideoMobileRef, { width: SelectedvideoMobileRefWidth, height: SelectedvideoMobileRefHeight }] =
		useElementSize();

	const params = useParams();
	const { itemid, brandid, specificationid, formatid } = decodeSlash(params);

	const item = useSelector(itemOverView);
	const features = item?.features_documents;
	const pictures = [...item?.pictures];
	const documents = useSelector(itemTechnicalOverview);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const lines = useSelector(orderLinesSelector);
	const isVariantloaded = useSelector(isVariantLoadedSelector({ itemid, brandid }));
	const itemsLoading = useSelector(itemIsLoadingSelector);
	const variantLoading = useSelector(variantIsLoadingSelector);
	const currency = useSelector(currencySelector);
	const displayImage = useSelector(displayImageSelector);
	const vSpecifications = useSelector(specificationsSelector());
	const vFormats = useSelector(formatsSelector());
	const formatsState = useSelector(variantFormats(specificationid));
	const isCartLoading = useSelector(isLoadingSelector);
	const isSealant = useSelector(isSealantSelector);

	const formatRef = useRef();
	const qtyRef = useRef();

	const [imageOverview, setImageOverview] = useState("");
	const [sds, setSds] = useState([]);
	const [tds, setTds] = useState([]);
	const [garantie, setGarantie] = useState([]);
	const [shippingRange, setShippingRange] = useState("");
	const [curFormat, setCurFormat] = useState();
	const [curSpecification, setCurSpecification] = useState();
	const [quoteRequested, setQuoteRequested] = useState(false);
	const [shakeError, setShakeError] = useState(false);
	const [featuresRender, setFeaturesRender] = useState();
	const [qty, setQty] = useState(1);
	const [qty_pack, setQty_pack] = useState(1);
	const [selected_picture, setSelected_picture] = useState(0);
	const [useCustomPrice, setUseCustomPrice] = useState(false);
	const [customerPrices, setCustomerPrices] = useState([]);
	const [curPrice, setCurPrice] = useState(0);
	const [sealantCalculaterOpen, setSealantCalculaterOpen] = useState(false);
	const [packagingFormat, setPackagingFormat] = useState("");
	const [showAll, setShowAll] = useState(false);
	const [showFormat, setShowFormat] = useState(false);

	const requestQuoteHandler = useCallback(() => {
		setQuoteRequested(true);
		dispatch(quoteRequest({ specificationId: specificationid, brandId: brandid, formatId: formatid }));
	}, [specificationid, brandid, formatid]);

	useEffect(() => {
		dispatch(setSearch(""));
		dispatch(setFavorite(false));
		dispatch(setSort(""));

		queryParams.delete("searchColor");
		queryParams.delete("sort");
		queryParams.delete("favorite");
		navigate(
			{
				pathname: location.pathname,
				search: queryParams.toString(),
			},
			{ replace: true }
		);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [itemid]);

	useEffect(() => {
		if (curSpecification && formatRef && formatRef?.current) {
			formatRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [curSpecification, formatRef, formatRef?.current]);
	useEffect(() => {
		if (curFormat && qtyRef && qtyRef?.current) {
			qtyRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [curFormat, qtyRef, qtyRef?.current]);

	useEffect(() => {
		let image = lngId.includes("fr") ? item.overViewImageFr : item.overViewImageEn;
		if (!image) {
			image = item.defaultImage;
		}
		setImageOverview(image);
	}, [lngId, item]);

	useEffect(() => {
		if (!props.outsidePage && item && item.brandErpCode && brandid === undefined) {
			navigate(
				{
					pathname: `/adstore/overview/${encodeSlash(itemid)}${
						item.brandErpCode ? "/" + encodeSlash(item.brandErpCode) : ""
					}${specificationid ? "/" + encodeSlash(specificationid) : ""}${
						formatid ? "/" + encodeSlash(formatid) : ""
					}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	}, [item, item?.brandErpCode, brandid]);

	useEffect(() => {
		if (currency && !itemsLoading && !variantLoading && isVariantloaded === false) {
			dispatch(getVariants({ item_id: itemid, brand_id: brandid }));
		}
	}, [lngId, item, currency, isLoggedIn, isVariantloaded]);

	useEffect(() => {
		setFeaturesRender(features?.filter((feature) => feature.language.includes(lngId.substring(0, 2))));
	}, [lngId]);

	useEffect(() => {
		dispatch(setBrand(brandid));
	}, [brandid]);

	useEffect(() => {
		if (curFormat && curFormat.multiple) {
			setQty(qty_pack * curFormat.multiple);
		} else setQty(1);
	}, [curFormat, qty_pack, curFormat?.multiple]);

	useEffect(() => {
		if (isVariantloaded === true && vSpecifications && vSpecifications.length === 1) {
			setCurSpecification(vSpecifications[0]);
			navigate(
				{
					pathname: `/adstore/overview/${encodeSlash(itemid)}${brandid ? "/" + encodeSlash(brandid) : ""}${
						item.isMultiVariant === true ? "/" + encodeSlash(vSpecifications[0].title) : ""
					}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
			if (item.isMultiVariant === false) {
				setCurFormat(vFormats[0]);
			}
		}
	}, [isVariantloaded]);

	useEffect(() => {
		setTds(
			documents
				.filter(
					(doc) =>
						(doc.title === "Technical Data Sheet" || doc.title === "Fiche technique") &&
						doc.language &&
						doc.language.includes(lngId.substring(0, 2))
				)
				.sort((a, b) => {
					const numA = parseInt(a.specification.split("-")[1], 10) || 0;
					const numB = parseInt(b.specification.split("-")[1], 10) || 0;
					return numA - numB;
				})
		);
		setSds(
			documents
				.filter(
					(doc) =>
						(doc.title === "Safety Data Sheet" || doc.title === "Fiche de données de sécurité") &&
						doc.language &&
						doc.language.includes(lngId.substring(0, 2))
				)
				.sort((a, b) => {
					const numA = parseInt(a.specification.split("-")[1], 10) || 0;
					const numB = parseInt(b.specification.split("-")[1], 10) || 0;
					return numA - numB;
				})
		);
		setGarantie(
			documents
				.filter(
					(doc) =>
						(doc.title === "General product warranty" || doc.title === "Garantie générale du produit") &&
						doc.language &&
						doc.language.includes(lngId.substring(0, 2))
				)
				.sort((a, b) => {
					const numA = parseInt(a.specification.split("-")[1], 10) || 0;
					const numB = parseInt(b.specification.split("-")[1], 10) || 0;
					return numA - numB;
				})
		);
	}, [lngId]);

	useEffect(() => {
		if (formatsState && formatsState.length === 1) {
			setCurFormat(formatsState[0]);
			if (formatsState[0]) {
				navigate(
					{
						pathname: `/adstore/overview/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}${specificationid ? "/" + encodeSlash(specificationid) : ""}${
							item.isMultiVariant === true ? "/" + encodeSlash(formatsState[0].erp_code) : ""
						}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			}
		}
		if (formatsState && formatsState.length > 0 && formatid) {
			navigate(
				{
					pathname: `/adstore/overview/${encodeSlash(itemid)}${
						brandid ? "/" + encodeSlash(brandid) : ""
					}/${encodeSlash(specificationid)}/${encodeSlash(formatid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
			setCurFormat(formatsState.find((f) => f.erp_code === formatid));
		} else {
			setCurFormat(undefined);
		}
		if (specificationid && vSpecifications)
			setCurSpecification(vSpecifications.find((spec) => spec.title === specificationid));
		else setCurSpecification(undefined);
	}, [formatsState, specificationid, formatid]);

	useEffect(() => {
		if (isLoggedIn && curFormat && curFormat.sales_prices && curFormat.sales_prices.length > 0) {
			let prices = curFormat.sales_prices?.filter((sales_price) => sales_price.qty <= qty);
			prices?.sort((a, b) => {
				if (Number(a.qty) < Number(b.qty)) return 1;
				if (Number(a.qty) > Number(b.qty)) return -1;
				return 0;
			});
			setCustomerPrices(prices);
		} else setCustomerPrices([]);
	}, [isLoggedIn, curFormat, qty]);

	useEffect(() => {
		if (curFormat) {
			const leadtime = curFormat?.leadTime > 0 ? curFormat?.leadTime : 10;
			setShippingRange(`${t(keys.PRODUCTS.VARIANT.AVAILBLE1)} ${leadtime} ${t(keys.PRODUCTS.VARIANT.AVAILBLE2)}`);
		}
	}, [t, lngId, curFormat, isLoggedIn, requestQuoteHandler]);

	useEffect(() => {
		setQty_pack(
			curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
				? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
				: 1
		);
	}, [curFormat, curFormat?.multiple, curFormat?.min_qty]);

	useEffect(() => {
		if (curFormat) {
			let generiquePrice = curFormat.price;
			if (generiquePrice) generiquePrice = Number(generiquePrice).toFixed(2);
			else generiquePrice = 0;
			if (customerPrices && customerPrices.length > 0) {
				const customPrice = customerPrices.find(
					(customerPrice) =>
						((generiquePrice && Number(customerPrice.price) < generiquePrice) ||
							Number(customerPrice.price) > 0) &&
						Number(customerPrice.qty) <= Number(qty)
				);
				if (customPrice?.price) {
					setCurPrice(customPrice?.price);
					setUseCustomPrice(true);
				} else {
					setCurPrice(generiquePrice);
					setUseCustomPrice(false);
				}
			} else {
				setCurPrice(generiquePrice);
				setUseCustomPrice(false);
			}
		}
	}, [customerPrices, customerPrices.length]);

	useEffect(() => {
		if (curFormat) {
			switch (curFormat.packaging) {
				case 1:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BAG));
					setShowFormat(false);
					break;
				case 2:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BARREL));
					setShowFormat(false);
					break;
				case 3:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BOX));
					setShowFormat(true);
					break;
				case 4:
					setPackagingFormat(t(keys.GLOBAL.COMMON.EACH));
					setShowFormat(false);
					break;
				case 5:
					setPackagingFormat(t(keys.GLOBAL.COMMON.CANE));
					setShowFormat(false);
					break;
				case 6:
					setPackagingFormat(t(keys.GLOBAL.COMMON.DRUM));
					setShowFormat(false);
					break;
				case 7:
					setPackagingFormat(t(keys.GLOBAL.COMMON.PAIL));
					setShowFormat(false);
					break;
				case 8:
					setPackagingFormat(t(keys.GLOBAL.COMMON.TOTE));
					setShowFormat(false);
					break;
				default:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BOX));
					setShowFormat(true);
					break;
			}
		}
	}, [t, lngId, curFormat]);

	const changeQtyHandler = (event) => {
		if (event.target.value) {
			setQty_pack(parseInt(event.target.value));
		} else {
			setQty_pack(1);
		}
	};

	const formatChangeHandler = (format) => {
		if (format) {
			setCurFormat(format);

			if (format.image) {
				const picIdx = pictures.findIndex((pic) => pic === format.image);
				if (picIdx >= 0) setSelected_picture(picIdx);
				else {
					pictures.push(format.image);
					setSelected_picture(pictures.length - 1);
				}
			}

			if (!formatid || formatid !== format.erp_code) {
				navigate(
					{
						pathname: `/adstore/overview/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}/${encodeSlash(specificationid)}/${encodeSlash(format.erp_code)}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			} else {
				navigate(
					{
						pathname: `/adstore/overview/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}/${encodeSlash(specificationid)}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			}
		} else {
			navigate(
				{
					pathname: `/adstore/overview/${encodeSlash(itemid)}${
						brandid ? "/" + encodeSlash(brandid) : ""
					}/${encodeSlash(specificationid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	};

	const specificationChangeHandler = (spec) => {
		if (spec.image) {
			const picIdx = pictures.findIndex((pic) => pic === spec.image);
			if (picIdx >= 0) setSelected_picture(picIdx);
			else {
				pictures.push(spec.image);
				setSelected_picture(pictures.length - 1);
			}
		}

		if (item.isMultiVariant) {
			if (specificationid && specificationid === spec.title) {
				navigate(
					{
						pathname: `/adstore/overview/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			} else {
				setCurSpecification(spec);
				navigate(
					{
						pathname: `/adstore/overview/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}/${encodeSlash(spec.title)}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			}
		}
	};

	const onBlurQtyHandler = (event) => {
		if (
			curFormat &&
			event.target.value <
				(curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1)
		) {
			setShakeError(true);
			setQty_pack(
				curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1
			);
			setTimeout(() => {
				setShakeError(false);
			}, 350);
		}
	};

	const addQty = () => {
		setQty_pack(qty_pack + 1);
	};

	const minusQty = () => {
		if (
			curFormat &&
			qty_pack - 1 <
				(curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1)
		) {
			setShakeError(true);
			setTimeout(() => {
				setShakeError(false);
			}, 350);

			return;
		}
		if (qty_pack > 1) setQty_pack(qty_pack - 1);
	};
	const addToCartHandler = () => {
		if (isLoggedIn) {
			if (curFormat) {
				const line = lines && lines.find((l) => l.variant.id === curFormat.variant_id);

				if (line) {
					dispatch(
						updateLine({
							id: line.id,
							variant_id: line.variant.id,
							qty: Number(qty) + Number(line.qty),
							qty_pack: Number(qty_pack) + Number(line.qty_pack),
							unitAmount: Number(curPrice),
							amount: ((Number(qty) + Number(line.qty)) * Number(curPrice)).toFixed(2),
							cartOpen: true,
						})
					);
				} else {
					dispatch(
						addLine({
							variant_id: curFormat.variant_id,
							qty: Number(qty),
							qty_pack: Number(qty_pack),
							unitAmount: Number(curPrice),
							amount: (Number(qty) * Number(curPrice)).toFixed(2),
						})
					);
				}
			}
		} else {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	};

	const viewColors = () => {
		if (item.isMultiVariant) {
			if (brandid) {
				navigate({
					pathname: `/adstore/variants/${itemid}/${brandid}/`,
					search: queryParams.toString(),
				});
			}
		} else {
			if (isLoggedIn) {
				const line = lines && lines.find((l) => l.variant.id === item.variantId);
				if (line) {
					dispatch(
						updateLine({
							id: line.id,
							variant_id: item.variantId,
							qty: Number(qty) + Number(line.qty),
							qty_pack: Number(qty_pack) + Number(line.qty_pack),
							unitAmount: Number(0),
							amount: ((Number(qty) + Number(line.qty)) * Number(0)).toFixed(2),
							cartOpen: true,
						})
					);
				} else {
					dispatch(
						addLine({
							variant_id: item.variantId,
							qty: Number(qty),
							qty_pack: Number(qty_pack),
							unitAmount: Number(0),
							amount: (Number(qty) * Number(0)).toFixed(2),
						})
					);
				}
			} else {
				navigate({ pathname: `/sign-in/`, search: queryParams.toString() });
			}
		}
	};

	function invertColor(hex) {
		if (hex.indexOf("#") === 0) {
			hex = hex.slice(1);
		}
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			return "#000000";
		}
		const r = 255 - parseInt(hex.slice(0, 2), 16);
		const g = 255 - parseInt(hex.slice(2, 4), 16);
		const b = 255 - parseInt(hex.slice(4, 6), 16);
		if (r * 0.299 + g * 0.587 + b * 0.114 < 186) return "#000000";
		else return "#ffffff";
	}

	const closeSealantCalculator = () => {
		setSealantCalculaterOpen(false);
	};

	const isPicture =
		(pictures && pictures.length > 1
			? pictures[selected_picture]
			: imageOverview
			? imageOverview
			: constant.DEFAULT_IMG
		).includes(".jpeg") ||
		(pictures && pictures.length > 1
			? pictures[selected_picture]
			: imageOverview
			? imageOverview
			: constant.DEFAULT_IMG
		).includes(".jpg") ||
		(pictures && pictures.length > 1
			? pictures[selected_picture]
			: imageOverview
			? imageOverview
			: constant.DEFAULT_IMG
		).includes(".png");

	const handleSeeMoreClick = () => {
		setShowAll(true);
	};

	return (
		<div ref={gridRef}>
			{sealantCalculaterOpen && (
				<SealantCalculatorModal
					close={() => {
						closeSealantCalculator();
					}}
				/>
			)}
			<div className={`${classes.productInfoSection} ${props.className || ""}`}>
				<div className={classes.productDisplay}>
					{isVariantloaded && vSpecifications && vSpecifications.length <= 10 && curSpecification && (
						<div
							className={classes.colorCardTitle}
							style={{
								backgroundColor: curSpecification.hex ? curSpecification.hex : "",
								color: curSpecification.hex ? invertColor(curSpecification.hex) : "",
							}}
						>
							{`${curSpecification.title} ${
								(lngId.includes("fr") ? curSpecification.subtitle_fr : curSpecification.subtitle_en) ??
								""
							}`}
						</div>
					)}
					<div className={classes.imageContainer} style={{ cursor: "default" }}>
						<div className={classes.contain} ref={SelectedvideoRef}>
							{isPicture ? (
								<img
									className={classes.overViewImg}
									src={
										pictures && pictures.length > 1
											? pictures[selected_picture]
											: imageOverview
											? imageOverview
											: constant.DEFAULT_IMG
									}
									alt="product-display.svg"
								/>
							) : pictures[selected_picture].includes("http") ? (
								<ReactPlayer
									url={pictures[selected_picture]}
									width={SelectedvideoRefWidth}
									height={SelectedvideoRefHeight}
									controls={true}
									playing={true}
								/>
							) : (
								<ReactPlayer
									url={`https://www.youtube.com/embed/${pictures[selected_picture]}?autoplay=1`}
									width={SelectedvideoRefWidth}
									height={SelectedvideoRefHeight}
									controls={true}
									playing={true}
								/>
							)}
						</div>
					</div>
					{pictures && pictures.length > 1 && (
						<CarouselProvider
							className={classes.carouselDesktop}
							naturalSlideWidth={1}
							naturalSlideHeight={1}
							totalSlides={pictures.length}
							visibleSlides={3}
							orientation={"horizontal"}
							dragEnabled={false}
						>
							<Slider className={classes.slider}>
								{pictures.map((url, index) => (
									<Slide innerClassName={classes.slide} index={index} key={index}>
										<div className={classes.contain} ref={videoRef}>
											{url.includes(".jpeg") || url.includes(".jpg") || url.includes(".png") ? (
												<img
													src={url}
													alt="url"
													onClick={() => {
														setSelected_picture(index);
													}}
													className={classes.slideImg}
													key={index}
												/>
											) : url.includes("http") ? (
												<div
													onClick={() => {
														setSelected_picture(index);
													}}
													className={classes.videoThumbnail}
												>
													<ReactPlayer
														url={url}
														width={videoRefWidth}
														height={videoRefHeight}
														className={classes.slideVideo}
														light={false}
													/>
													<Icon className={classes.icon} icon="ion:play-sharp" />
												</div>
											) : (
												<div className={classes.videoThumbnail}>
													<img
														src={`https://img.youtube.com/vi/${url}/hqdefault.jpg`}
														alt="url"
														onClick={() => {
															setSelected_picture(index);
														}}
														className={classes.slideVideo}
														key={index}
													/>
													<Icon className={classes.icon} icon="ion:play-sharp" />
												</div>
											)}
										</div>
									</Slide>
								))}
							</Slider>
							<div className={classes.buttons}>
								<ButtonBack className={classes.btn}>
									<Icon className={classes.icon} icon="material-symbols:arrow-left" />
								</ButtonBack>
								<ButtonNext className={classes.btn}>
									<Icon className={classes.icon} icon="material-symbols:arrow-right" />
								</ButtonNext>
							</div>
						</CarouselProvider>
					)}
				</div>
				<div className={classes.productInfoContainer}>
					<div className={classes.titleContainer}>
						<h1>{lngId.includes("fr") && item.title_fr ? item.title_fr : item.title_en}</h1>
						{(lngId.includes("fr") ? item.label_fr : item.label_en) && (
							<h2>{lngId.includes("fr") ? item.label_fr : item.label_en}</h2>
						)}
						{(lngId.includes("fr") ? item.text_fr : item.text_en) && (
							<p>{lngId.includes("fr") ? item.text_fr : item.text_en}</p>
						)}
					</div>
					{featuresRender && featuresRender.length > 0 && (
						<div className={classes.featuresContainer}>
							<hr />
							<div className={classes.productDisplay}>
								{pictures && pictures.length > 1 && (
									<CarouselProvider
										className={classes.carouselMobile}
										naturalSlideWidth={1}
										naturalSlideHeight={1}
										totalSlides={pictures.length}
										visibleSlides={1.1}
									>
										<Slider className={classes.slider}>
											{pictures.map((url, index) => (
												<Slide innerClassName={classes.slide} index={index} key={index}>
													<div className={classes.contain} ref={SelectedvideoMobileRef}>
														{url.includes(".jpeg") ||
														url.includes(".jpg") ||
														url.includes(".png") ? (
															<img
																src={url}
																alt="url"
																onClick={() => {
																	setSelected_picture(index);
																}}
																className={classes.slideImg}
																key={index}
															/>
														) : url.includes("http") ? (
															<ReactPlayer
																url={url}
																width={SelectedvideoMobileRefWidth}
																height={SelectedvideoMobileRefHeight}
																controls={true}
																playing={false}
															/>
														) : (
															<ReactPlayer
																url={`https://www.youtube.com/embed/${url}?autoplay=1`}
																width={SelectedvideoMobileRefWidth}
																height={SelectedvideoMobileRefHeight}
																controls={true}
																playing={false}
															/>
														)}
													</div>
												</Slide>
											))}
										</Slider>
										{pictures.length > 1 && (
											<div className={classes.pickerDots}>
												{pictures.map((record, index) => (
													<Dot className={classes.icon} slide={index} key={index} />
												))}
											</div>
										)}
									</CarouselProvider>
								)}
								{pictures && pictures.length === 1 && gridWidth <= 833 && (
									<img
										className={classes.overViewImg}
										src={imageOverview ? imageOverview : constant.DEFAULT_IMG}
										alt="product-display.svg"
									/>
								)}
							</div>
							<h3>{t(keys.PRODUCTS.OVERVIEW.FEATURES)}</h3>
							{gridWidth > 1068 ? (
								<ul>
									{featuresRender.map((feature) => (
										<li key={feature.id}>{feature.text}</li>
									))}
								</ul>
							) : (
								<>
									<ul>
										{showAll
											? featuresRender.map((feature) => <li key={feature.id}>{feature.text}</li>)
											: featuresRender
													.slice(0, 4)
													.map((feature) => <li key={feature.id}>{feature.text}</li>)}
									</ul>
									{featuresRender.length > 4 && !showAll && (
										<span className={classes.more} onClick={handleSeeMoreClick}>
											{t(keys.PRODUCTS.VIEWMORE)}
										</span>
									)}
								</>
							)}
						</div>
					)}
					{(tds.length > 0 || sds.length > 0) && (
						<>
							<div className={classes.docContainer}>
								<div className={classes.Container}>
									{tds &&
										tds.map((td) => (
											<CustomNavLink
												key={td.id}
												className={classes.doc}
												to={td.url}
												target={"_blank"}
											>
												<img
													className={classes.ds_img}
													src={lngId.includes("fr") ? fts_svg : tds_svg}
													alt={tds_svg}
												/>
												<div className={classes.container}>
													<p>
														{t(keys.PRODUCTS.OVERVIEW.TDS)} {td.specification}
													</p>
												</div>
											</CustomNavLink>
										))}
								</div>
								<div className={classes.Container}>
									{sds &&
										sds.map((sd) => (
											<CustomNavLink
												key={sd.id}
												className={classes.doc}
												to={sd.url}
												target={"_blank"}
											>
												<img
													className={classes.ds_img}
													src={lngId.includes("fr") ? fds_svg : sds_svg}
													alt={sds_svg}
												/>
												<div className={classes.container}>
													<p>
														{t(keys.PRODUCTS.OVERVIEW.SDS)} {sd.specification}
													</p>
												</div>
											</CustomNavLink>
										))}
								</div>
							</div>
							<div className={classes.garntieContainer}>
								{props.outsidePage &&
									props.garanti &&
									garantie &&
									garantie.map((sd) => (
										<CustomNavLink
											key={sd.id}
											className={classes.doc}
											to={sd.url}
											target={"_blank"}
										>
											<img className={classes.pdf_img} src={pdf} alt={pdf} />
											<div className={classes.container}>
												<p>{t(keys.PRODUCTS.OVERVIEW.GARANTIE)}</p>
											</div>
										</CustomNavLink>
									))}
							</div>
						</>
					)}
					{isVariantloaded && vSpecifications && vSpecifications.length <= 10 && (
						<div className={classes.layout}>
							{vSpecifications && vSpecifications.length > 1 && (
								<Fragment>
									<h3>1- {t(keys.PRODUCTS.OVERVIEW.SELECT_OPTION)}</h3>
									<CustomNavLink
										className={classes.element}
										to={`/tools/find-the-right-sealant/${search}`}
										target={"_self"}
									>
										<div className={classes.text}>
											<p>{t(keys.TOOLS.SEALANTCALCULATOR.COLOR_MATCH)}</p>
										</div>
										<img src={colorMatching} alt="tube.svg" />
									</CustomNavLink>
									<div
										className={classes.container}
										style={{
											gridTemplateColumns: displayImage
												? "repeat(auto-fill, minmax(15rem, 1fr))"
												: "repeat(auto-fill, minmax(10rem, 1fr))",
										}}
									>
										{displayImage &&
											vSpecifications.map((spec) => (
												<Button
													key={`${spec.title}`}
													id={spec.title}
													className={classes.sbuttons}
													content={classes.content}
													style={{ height: "6rem" }}
													color={
														specificationid && specificationid === spec.title
															? "primary"
															: "outlineBlack"
													}
													size="big"
													onClick={() => specificationChangeHandler(spec)}
												>
													{spec.title && <p>{spec.title}</p>}
													{lngId.includes("fr")
														? spec.subtitle_fr
														: spec.subtitle_en && (
																<p>
																	{lngId.includes("fr")
																		? spec.subtitle_fr
																		: spec.subtitle_en}
																</p>
														  )}
												</Button>
											))}
										{!displayImage &&
											vSpecifications.map((spec) => (
												<div
													key={spec.id}
													className={`${classes.colorCardContainer} ${
														specificationid && specificationid === spec.title
															? classes.selected
															: ""
													}`}
													onClick={() => specificationChangeHandler(spec)}
												>
													<div
														className={`${classes.colorCardImageDiv} }`}
														style={{ backgroundColor: spec.hex ?? "#000000" }}
													/>
													<div className={classes.colorCardDetails}>
														{spec.title && <p>{spec.title}</p>}
														<p>
															{lngId.includes("fr") ? spec.subtitle_fr : spec.subtitle_en}
														</p>
													</div>
												</div>
											))}
									</div>
									<div
										className={
											curSpecification
												? `${classes.containerMobile} ${classes.selectedContainer}`
												: classes.containerMobile
										}
									>
										<div className={classes.scrollableContainer}>
											{curSpecification ? (
												displayImage ? (
													<Fragment>
														<Button
															key={`${curSpecification.title}`}
															id={curSpecification.title}
															className={classes.sbuttons}
															color={
																specificationid &&
																specificationid === curSpecification.title
																	? "primary"
																	: "outlineBlack"
															}
															size="big"
															onClick={() => specificationChangeHandler(curSpecification)}
														>
															{curSpecification.title && <p>{curSpecification.title}</p>}
															{lngId.includes("fr")
																? curSpecification.subtitle_fr
																: curSpecification.subtitle_en && (
																		<p>
																			{lngId.includes("fr")
																				? curSpecification.subtitle_fr
																				: curSpecification.subtitle_en}
																		</p>
																  )}
														</Button>
													</Fragment>
												) : (
													<Fragment>
														<div
															key={curSpecification.id}
															className={`${classes.colorCardContainer} ${classes.selected}`}
															onClick={() => specificationChangeHandler(curSpecification)}
														>
															<div className={classes.colorCardDetails}>
																{curSpecification.title && (
																	<p>{curSpecification.title}</p>
																)}
																<p>
																	{lngId.includes("fr")
																		? curSpecification.subtitle_fr
																		: curSpecification.subtitle_en}
																</p>
															</div>
															<div
																className={`${classes.colorCardImageDiv}`}
																style={{
																	backgroundColor: curSpecification.hex ?? "#000000",
																}}
															/>
														</div>
													</Fragment>
												)
											) : displayImage ? (
												vSpecifications.map((spec) => (
													<Button
														key={`${spec.title}`}
														id={spec.title}
														className={classes.sbuttons}
														color={
															specificationid && specificationid === spec.title
																? "primary"
																: "outlineBlack"
														}
														onClick={() => specificationChangeHandler(spec)}
													>
														{spec.title && <p>{spec.title}</p>}
														{lngId.includes("fr")
															? spec.subtitle_fr
															: spec.subtitle_en && (
																	<p>
																		{lngId.includes("fr")
																			? spec.subtitle_fr
																			: spec.subtitle_en}
																	</p>
															  )}
													</Button>
												))
											) : (
												vSpecifications.map((spec) => (
													<div
														key={spec.id}
														className={`${classes.colorCardContainer} ${
															specificationid && specificationid === spec.title
																? classes.selected
																: ""
														}`}
														onClick={() => specificationChangeHandler(spec)}
													>
														<div className={classes.colorCardDetails}>
															{spec.title && <p>{spec.title}</p>}
															<p>
																{lngId.includes("fr")
																	? spec.subtitle_fr
																	: spec.subtitle_en}
															</p>
														</div>
														<div
															className={`${classes.colorCardImageDiv}`}
															style={{ backgroundColor: spec.hex ?? "#000000" }}
														/>
													</div>
												))
											)}
											{!curSpecification && <div className={classes.rectangle}></div>}
										</div>
									</div>
								</Fragment>
							)}
							{formatsState &&
								(formatsState.length > 1 ||
									(formatsState.length === 1 &&
										formatsState[0].erp_code !== "n/a" &&
										formatsState[0].erp_code)) && (
									<h3>
										{vSpecifications && vSpecifications.length > 1 ? "2-" : "1-"}{" "}
										{t(keys.PRODUCTS.OVERVIEW.SELECT_FORMAT)}
									</h3>
								)}
							{formatsState &&
								(formatsState.length > 1 ||
									(formatsState.length === 1 &&
										formatsState[0].erp_code !== "n/a" &&
										formatsState[0].erp_code)) && (
									<Fragment>
										<div
											ref={formatRef}
											className={classes.container}
											style={{ gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 1fr))" }}
										>
											{formatsState.map((format) => (
												<Button
													key={`${format.erp_code}`}
													id={format.erp_code}
													className={classes.fbuttons}
													content={classes.content}
													color={
														formatid && formatid === format.erp_code
															? "primary"
															: "outlineBlack"
													}
													size="big"
													onClick={() => formatChangeHandler(format)}
													disabled={!specificationid}
												>
													{getLocalizedValueByCustomMapping(format, {
														en_us: "conversion_en_us",
														default: "erp_code",
													})}
												</Button>
											))}
										</div>
										<Dropdown
											className={classes.inputJoint}
											onChange={(event) => {
												const selectedValue = event.target.value;
												const selectedFormat = formatsState.find(
													(format) => format.erp_code === selectedValue
												);
												formatChangeHandler(selectedFormat);
											}}
											wrapperClassName={classes.after}
											value={curFormat?.erp_code}
										>
											<option key="default" value={undefined}>
												{t(keys.PRODUCTS.OVERVIEW.SELECT_YOUR_FORMAT)}
											</option>
											{formatsState.map((format) => (
												<option key={format.erp_code} value={format.erp_code}>
													{format.erp_code}
												</option>
											))}
										</Dropdown>
									</Fragment>
								)}
							<h3>
								{vSpecifications && vSpecifications.length > 1 && vFormats && vFormats.length > 1
									? "3-"
									: (vSpecifications && vSpecifications.length > 1) ||
									  (vFormats &&
											(vFormats.length > 1 ||
												(vFormats.length === 1 &&
													vFormats[0].erp_code !== "n/a" &&
													vFormats[0].erp_code)))
									? "2-"
									: ""}{" "}
								{t(keys.PRODUCTS.OVERVIEW.SELECT_QUANTITY)}
							</h3>
							<div className={classes.information}>
								<div className={classes.prices}>
									{curFormat && Number(curFormat.price) > 0 && useCustomPrice === false && (
										<p>
											<span>{t(keys.PRODUCTS.VARIANT.LIST_PRICE)}</span>
											<span
												className={
													customerPrices && customerPrices.length > 0 ? classes.disable : ""
												}
											>
												<span className={classes.price}>
													{lngId.includes("en") ? "$" : ""}
													{Number(curFormat?.price)
														.toFixed(2)
														.toString()
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															lngId.includes("fr") ? " " : ","
														)
														.replace(".", lngId.includes("fr") ? "," : ".")}
													{lngId.includes("fr") ? "$" : ""}
												</span>
												<span className={classes.price}>
													/
													{(lngId.includes("fr")
														? curFormat?.description_fr
														: curFormat?.description_en
														? lngId.includes("fr")
															? curFormat?.description_fr
															: curFormat?.description_en
														: curFormat?.erp_code) ?? t(keys.GLOBAL.COMMON.UNIT)}
												</span>
												{showFormat && (
													<span>
														{" "}
														({curFormat && curFormat?.multiple
															? curFormat?.multiple
															: 1}{" "}
														{(curFormat && curFormat?.multiple ? curFormat?.multiple : 1) >
														1
															? t(keys.GLOBAL.COMMON.UNITS)
															: t(keys.GLOBAL.COMMON.UNIT)}{" "}
														{t(keys.GLOBAL.COMMON.PER)} {packagingFormat})
													</span>
												)}
											</span>
										</p>
									)}
									{useCustomPrice === true && (
										<p>
											<span>{t(keys.PRODUCTS.VARIANT.YOUR_PRICE)}</span>
											<span
												className={
													customerPrices && customerPrices.length > 0 ? classes.disable : ""
												}
											>
												<span className={classes.price}>
													{lngId.includes("en") ? "$" : ""}
													{Number(curPrice)
														.toFixed(2)
														.toString()
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															lngId.includes("fr") ? " " : "."
														)
														.replace(".", lngId.includes("fr") ? "," : ".")}
													{lngId.includes("fr") ? "$" : ""}
												</span>
												<span className={classes.price}>/{t(keys.GLOBAL.COMMON.UNIT)}</span>
												{showFormat && (
													<span>
														{" "}
														({curFormat && curFormat?.multiple
															? curFormat?.multiple
															: 1}{" "}
														{(curFormat && curFormat?.multiple ? curFormat?.multiple : 1) >
														1
															? t(keys.GLOBAL.COMMON.UNITS)
															: t(keys.GLOBAL.COMMON.UNIT)}{" "}
														{t(keys.GLOBAL.COMMON.PER)} {packagingFormat})
													</span>
												)}
											</span>
										</p>
									)}
									<div className={classes.mobileMessages}>
										{curFormat && useCustomPrice === false && (
											<div className={classes.helper}>
												{isLoggedIn && !quoteRequested && (
													<p className={classes.link} onClick={requestQuoteHandler}>
														{t(keys.PRODUCTS.VARIANT.GET_QUOTE)}
													</p>
												)}
												{isLoggedIn && quoteRequested && (
													<p>{t(keys.PRODUCTS.VARIANT.QUOTE_SENT)}</p>
												)}
												{!isLoggedIn && (
													<CustomNavLink
														to={`/sign-in`}
														className={`${classes.link} ${classes.loginlink}`}
													>
														{t(keys.GLOBAL.COMMON.LOGIN_TO_SEE_PRICE)}
													</CustomNavLink>
												)}
											</div>
										)}
									</div>
									<div className={classes.warnings}>
										{(curFormat &&
											curFormat?.min_qty &&
											curFormat?.min_qty > curFormat?.multiple &&
											curFormat?.on_hand <= curFormat?.min_qty) === true && (
											<div
												className={`${classes.warning} ${
													shakeError === true ? classes.shakeError : ""
												}`}
											>
												<div className={classes.iconContainer}>
													<Icon className={classes.icon} icon="material-symbols:circle" />
												</div>
												<span>{`${t(keys.PRODUCTS.VARIANT.MIN_ORDER)} ${
													curFormat?.min_qty > 0 &&
													curFormat?.multiple &&
													curFormat?.multiple !== 0
														? (curFormat?.min_qty / curFormat?.multiple).toFixed(0)
														: curFormat?.min_qty
												}`}</span>
											</div>
										)}
										{curFormat && Number(curFormat.on_hand) / Number(curFormat.multiple) < 1 && (
											<div className={classes.warning}>
												<div className={classes.iconContainer}>
													<Icon className={classes.icon} icon="material-symbols:circle" />
												</div>
												<span>{shippingRange}</span>
											</div>
										)}
									</div>
									{curFormat && Number(curFormat.on_hand) / Number(curFormat.multiple) >= 1 && (
										<div className={classes.info}>
											<div className={classes.iconContainer}>
												<Icon className={classes.icon} icon="material-symbols:circle" />
											</div>
											<span>{`${
												Number(curFormat.multiple) === 0
													? 0
													: (Number(curFormat.on_hand) / Number(curFormat.multiple)).toFixed(
															0
													  )
											} ${packagingFormat} ${t(keys.CART.OVERVIEW.AVAILABLE)}`}</span>
										</div>
									)}
								</div>
								<div className={classes.messages}>
									{curFormat && useCustomPrice === false && (
										<div className={classes.helper}>
											{isLoggedIn && !quoteRequested && (
												<p className={classes.link} onClick={requestQuoteHandler}>
													{t(keys.PRODUCTS.VARIANT.GET_QUOTE)}
												</p>
											)}
											{isLoggedIn && quoteRequested && (
												<p>{t(keys.PRODUCTS.VARIANT.QUOTE_SENT)}</p>
											)}
											{!isLoggedIn && (
												<CustomNavLink
													to={`/sign-in`}
													className={`${classes.link} ${classes.loginlink}`}
												>
													{t(keys.GLOBAL.COMMON.LOGIN_TO_SEE_PRICE)}
												</CustomNavLink>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{isVariantloaded && vSpecifications && vSpecifications.length <= 10 && (
						<div className={classes.qty} ref={qtyRef}>
							{curFormat && (
								<div className={classes.numbers}>
									<div className={classes.center}>
										<div className={classes.row}>
											<div className={classes.qtyButtons}>
												<Button
													id="minus"
													className={classes.buttons}
													onClick={minusQty}
													color="outlineBlack"
													size="small"
													style={{ borderRight: "0" }}
												>
													-
												</Button>
												<TextInput
													id="value"
													className={classes.textInput}
													inputClassName={classes.input}
													onChange={changeQtyHandler}
													onBlur={onBlurQtyHandler}
													type="number"
													value={qty_pack}
													shakeError={shakeError}
												/>
												<Button
													id="add"
													className={classes.buttons}
													onClick={addQty}
													color="outlineBlack"
													size="small"
													style={{ borderLeft: "0" }}
												>
													+
												</Button>
											</div>
											{curFormat && (
												<h1>
													{`${
														(curFormat && curFormat.multiple ? curFormat?.multiple : 1) *
														qty_pack
													}
												${
													(curFormat && curFormat.multiple ? curFormat?.multiple : 1) *
														qty_pack >
													1
														? t(keys.GLOBAL.COMMON.UNITS)
														: t(keys.GLOBAL.COMMON.UNIT)
												}`}
												</h1>
											)}
										</div>
										{isSealant && (
											<div className={classes.right}>
												{item.isMultiVariant && (
													<div
														className={classes.container}
														onClick={() => setSealantCalculaterOpen(true)}
													>
														<div className={classes.first}>
															<div className={classes.iconContainer}>
																<Icon
																	className={classes.calculator}
																	icon="bi:calculator-fill"
																/>
															</div>
															<h1>{t(keys.PRODUCTS.OVERVIEW.CALC_TOOL)}</h1>
														</div>
														<p>{t(keys.PRODUCTS.OVERVIEW.CALCULATOR)}</p>
													</div>
												)}
											</div>
										)}
									</div>
									<Button
										className={classes.addCartButton}
										onClick={addToCartHandler}
										disabled={isCartLoading || shakeError || curPrice <= 0}
									>
										{t(keys.PRODUCTS.OVERVIEW.ADD_TO_CART_BTN)}
									</Button>
								</div>
							)}
						</div>
					)}
					{!props.outsidePage &&
						!(isVariantloaded && vSpecifications && vSpecifications.length <= 10) &&
						!itemsLoading &&
						!variantLoading && (
							<Button
								className={classes.selectVariant}
								onClick={viewColors}
								disabled={!item.isMultiVariant}
							>
								{item.isMultiVariant
									? t(keys.PRODUCTS.OVERVIEW.VIEW_COLORS_BTN)
									: t(keys.PRODUCTS.OVERVIEW.ADD_TO_CART_BTN)}
							</Button>
						)}
				</div>
			</div>
		</div>
	);
};
export default ProductInfo;
