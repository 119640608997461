import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HTMLString from "react-html-string";

import { keys } from "../../locales/localeskeys";

import TextInputOverlay from "../ui/Input/TextInputOverlay/TextInputOverlay";
import Accordion from "../ui/Accordion/Accordion";
import Loading from "../ui/loading/Loading";
import { HeaderPage } from "../../commons";

import { useLocalized, useSearch } from "../../hooks/";

import { searchSelector } from "../../store/productQuery";
import { getFaq, faqSelector, isLoadingSelector } from "../../store/faq";

import classes from "./FAQ.module.scss";

const FAQ = ({ classes }) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const { getLocalizedValueByKeyType } = useLocalized(i18n.language);

	const dispatch = useDispatch();

	const tabBarValues = Object.values(keys.FAQ.TAB_BAR);
	const [activeTab, setActiveTab] = useState(2);
	const handleActiveBar = (key) => {
		setActiveTab(key);
	};

	const faqs = useSelector(faqSelector);
	const isLoading = useSelector(isLoadingSelector);

	const searchRef = useRef();
	const searchState = useSelector(searchSelector);
	const { isMatchInTarget, searchSubmitHandler, searchChangeHandler, enterSubmitHandler } = useSearch({});

	const highlightText = (string, term) => {
		const regex = new RegExp(`(${term})`, "gi");

		const tagRegex = /(<[^>]+>)/g;

		const parts = string.split(tagRegex);

		const segments = parts.map((part, i) => {
			if (tagRegex.test(part)) {
				return part;
			}

			return part
				.split(regex)
				.map((subPart, j) =>
					regex.test(subPart) ? `<span style="background-color: yellow">${subPart}</span>` : subPart
				);
		});

		return segments.flat().join("");
	};

	const filteredFaqs = useMemo(() => {
		const populatedFaqs = faqs.filter(
			(item) => getLocalizedValueByKeyType(item, "question") != "" && getLocalizedValueByKeyType(item, "answer") != ""
		);

		if (searchState != "") {
			return populatedFaqs
				.filter((item) => {
					const question = getLocalizedValueByKeyType(item, "question").toLowerCase();
					const answer = getLocalizedValueByKeyType(item, "answer").toLowerCase();
					const search = searchState.toLowerCase().trim();

					return isMatchInTarget(question, search) || isMatchInTarget(answer, search);
				})
				.map((item) => {
					return {
						...item,
						question_en: highlightText(item.question_en ?? "", searchState),
						answer_en: highlightText(item.answer_en ?? "", searchState),
						question_fr: highlightText(item.question_fr ?? "", searchState),
						answer_fr: highlightText(item.answer_fr ?? "", searchState),
					};
				});
		} else {
			return populatedFaqs;
		}
	}, [faqs, searchState]);

	useEffect(() => {
		dispatch(getFaq());
	}, [dispatch]);

	return (
		<div className="pages-layout">
			<HeaderPage 
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: `/?lng=${lngId}` },
					{ label: "FAQ", to: `/faq/?lng=${lngId}` },
				]}
				title={t(keys.FAQ.TITLE)}
			/>
			<div className={classes.tab_bar}>
				<ul>
					{tabBarValues.map((item, key) => (
						<li
							key={item}
							className={`${classes.bold} ${activeTab === key ? classes.active : ""}`}
							onClick={() => handleActiveBar(key)}
						>
							{t(item)}
						</li>
					))}
				</ul>
			</div>
			<div className={classes.search}>
				<TextInputOverlay
					ref={searchRef}
					id="search"
					label={t(keys.GLOBAL.SEARCH)}
					value={searchState ? searchState : ""}
					onChange={searchChangeHandler}
					onKeyDown={enterSubmitHandler}
					onSearch={searchSubmitHandler}
				/>
			</div>
			<div className={`${classes.subtitle} ${classes.bold}`}>FAQ</div>
			{isLoading && <Loading type={"bounce"} />}
			{!isLoading && (
				<div>
					{filteredFaqs.map((item) => (
						<Accordion
							key={item.id}
							className={classes.questionSection}
							header={getLocalizedValueByKeyType(item, "question")}
							headerClass={classes.questionHeader}
							headerClassActive={classes.questionHeaderActive}
							iconClass={classes.questionIconClass}
							accordionBody={classes.questionBody}
						>
							<div className={classes.questionContent}>
								<HTMLString html={getLocalizedValueByKeyType(item, "answer")} />
							</div>

						</Accordion>
					))}
				</div>
			)}
		</div>
	);
};

FAQ.defaultProps = {
	classes,
};

export default FAQ;
