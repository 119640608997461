import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

import { keys } from "../../../../locales/localeskeys";

import { getPaymentMethods, paymentMethodsSelector, setPaymentMethod } from "../../../../store/paymentMethod";

import { Button } from "../../../../atoms/button/Button";
import Card from "../../card/Card";

import classes from "./PaymentMethodsList.module.scss";

export const PaymentMethodsList = ({ classes }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const paymentMethods = useSelector(paymentMethodsSelector);

	const navigate = useNavigate();

	const isExpirationNear = (expirationDate) => {
		const now = new Date();
		const expiresAt = new Date(expirationDate);
		const diffMonths = (expiresAt.getFullYear() - now.getFullYear()) * 12 + (expiresAt.getMonth() - now.getMonth());
		return diffMonths <= 3;
	};

	const handleAddPaymentMethod = () => {
		dispatch(setPaymentMethod({}));
		navigate("/account/payment-method");
	};

	const handleSetByDefault = (paymentMethod) => {
		console.log(paymentMethod);
	};

	const handleOnEdit = (paymentMethod) => {
		dispatch(setPaymentMethod(paymentMethod));
		navigate("/account/payment-method");
	};

	const handleOnRemove = (paymentMethod) => {
		console.log(paymentMethod);
	};

	useEffect(() => {
		dispatch(getPaymentMethods());
	}, [dispatch]);

	return (
		<div className={classes.frameset}>
			<div className={classes.header}>
				<Button onClick={handleAddPaymentMethod} icon="material-symbols:add">
					{t(keys.ACCOUNT.PAYMENT_METHODS.ADD_BUTTON_LABEL)}
				</Button>
			</div>
			<div className={classes.content}>
				{paymentMethods.map((data, key) => (
					<Card
						key={key}
						title={data.label}
						isDefault={data.is_default}
						onChange={() => handleSetByDefault(data)}
						onEdit={() => handleOnEdit(data)}
						onRemove={() => handleOnRemove(data)}
					>
						<div>
							{`${
								data.card_type === "DEBITCARD"
									? t(keys.ACCOUNT.PAYMENT_METHODS.DEBIT_CARD)
									: t(keys.ACCOUNT.PAYMENT_METHODS.CREDIT_CARD)
							} ${t(keys.ACCOUNT.PAYMENT_METHODS.ENDING_IN)} **** ${data.ending_card}`}
						</div>
						<div className={isExpirationNear(data.expires_at) ? classes.nearExpiration : ""}>
							{isExpirationNear(data.expires_at) && <Icon className={classes.nearExpiration} icon="material-symbols:warning" />}
							{`${t(keys.ACCOUNT.PAYMENT_METHODS.EXPIRE_ON)} ${new Date(
								data.expires_at
							).toLocaleDateString("en-US", { month: "2-digit", year: "numeric" })}`}
						</div>
					</Card>
				))}
			</div>
		</div>
	);
};

PaymentMethodsList.defaultProps = {
	classes,
};
