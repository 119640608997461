import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import encodeSlash from "../../../../../hooks/encodeSlash";
import decodeSlash from "../../../../../hooks/decodeSlash";
import useElementSize from "../../../../../hooks/useElementSize";
import Dropdown from "../../../../ui/Input/Dropdown/Dropdown";

import TextInput from "../../../../ui/Input/TextInput/TextInput";
import Button from "../../../../ui/Button/Button";
import CustomNavLink from "../../../../ui/customNavLink/CustomNavLink";
import SealantCalculatorModal from "../../../ProductOverview/SealantCalculator/SealantCalculatorModal";

import {
	addSpecificationFavorite,
	currencySelector,
	customerFavoriteSpecificationSelector,
	deleteSpecificationFavorite,
	isLoggedInSelector,
	quoteRequest,
	setPrecedentUrl,
} from "../../../../../store/auth";
import { addLine, isLoadingSelector, orderLinesSelector, updateLine } from "../../../../../store/cart";
import { favoriteSelector, searchSelector, setBrand, setFavorite, setSearch } from "../../../../../store/variantQuery";
import { itemIsLoadingSelector, itemOverView } from "../../../../../store/product";
import {
	formatsSelector,
	getVariants,
	isVariantLoadedSelector,
	variantFormats,
	variantIsLoadingSelector,
	variantSpecificationsNonStock,
	variantSpecificationsStock,
} from "../../../../../store/variant";

import colorMatching from "../../../../../assets/svg/color-matching.svg";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./VariantInfo.module.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import SearchBar from "../../../../ui/Input/SearchBar/SearchBar";
import ToggleSwitch from "../../../../ui/Input/ToggleSwitch/ToggleSwitch";

const VariantInfo = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const [gridRef, { width: gridWidth }] = useElementSize();

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const search = `?lng=${lngId}`;

	const params = useParams();
	const { itemid, brandid, specificationid, formatid } = decodeSlash(params);

	const item = useSelector(itemOverView);
	const isLoggedIn = useSelector(isLoggedInSelector);
	const lines = useSelector(orderLinesSelector);
	const isVariantloaded = useSelector(isVariantLoadedSelector({ itemid, brandid }));
	const itemsLoading = useSelector(itemIsLoadingSelector);
	const variantLoading = useSelector(variantIsLoadingSelector);
	const currency = useSelector(currencySelector);
	const vFormats = useSelector(formatsSelector());
	const formatsState = useSelector(variantFormats(specificationid));
	const isCartLoading = useSelector(isLoadingSelector);
	const specificationsStock = useSelector(variantSpecificationsStock());
	const specificationsNonStock = useSelector(variantSpecificationsNonStock());
	const favoriteSpecifications = useSelector(customerFavoriteSpecificationSelector);
	const favoriteState = useSelector(favoriteSelector);
	const searchState = useSelector(searchSelector);

	const formatRef = useRef();
	const qtyRef = useRef();
	const searchRef = useRef();

	const [shippingRange, setShippingRange] = useState("");
	const [curFormat, setCurFormat] = useState();
	const [curSpecification, setCurSpecification] = useState();
	const [quoteRequested, setQuoteRequested] = useState(false);
	const [shakeError, setShakeError] = useState(false);
	const [qty, setQty] = useState(1);
	const [qty_pack, setQty_pack] = useState(1);
	const [useCustomPrice, setUseCustomPrice] = useState(false);
	const [customerPrices, setCustomerPrices] = useState([]);
	const [curPrice, setCurPrice] = useState(0);
	const [sealantCalculaterOpen, setSealantCalculaterOpen] = useState(false);
	const [packagingFormat, setPackagingFormat] = useState("");
	const [showFormat, setShowFormat] = useState(false);
	const [vSpecifications, setvSpecifications] = useState(specificationsStock);
	const [vSpecificationRend, setvSpecificationRend] = useState(true);
	const [isSubmited, setIsSubmited] = useState(false);
	const [oldSearch, setOldSearch] = useState("");

	const requestQuoteHandler = useCallback(() => {
		setQuoteRequested(true);
		dispatch(quoteRequest({ specificationId: specificationid, brandId: brandid, formatId: formatid }));
	}, [specificationid, brandid, formatid]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [itemid]);

	useEffect(() => {
		if (isLoggedIn !== undefined) {
			if (currency && !itemsLoading && !variantLoading && (isVariantloaded === false || isSubmited === true)) {
				dispatch(getVariants({ item_id: itemid, brand_id: brandid }));
				setIsSubmited(false);
			}
		}
	}, [isLoggedIn, searchState, favoriteState, itemid, brandid, currency, isSubmited, isVariantloaded]);

	useEffect(() => {
		if (curSpecification && formatRef && formatRef?.current) {
			formatRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [curSpecification, formatRef, formatRef?.current]);
	useEffect(() => {
		if (curFormat && qtyRef && qtyRef?.current) {
			qtyRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [curFormat, qtyRef, qtyRef?.current]);

	useEffect(() => {
		if (!props.outsidePage && item && item.brandErpCode && brandid === undefined) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}${
						item.brandErpCode ? "/" + encodeSlash(item.brandErpCode) : ""
					}${specificationid ? "/" + encodeSlash(specificationid) : ""}${
						formatid ? "/" + encodeSlash(formatid) : ""
					}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	}, [item, item?.brandErpCode, brandid]);

	useEffect(() => {
		if (currency && !itemsLoading && !variantLoading && isVariantloaded === false) {
			dispatch(getVariants({ item_id: itemid, brand_id: brandid }));
		}
	}, [lngId, item, currency, isLoggedIn, isVariantloaded]);

	useEffect(() => {
		dispatch(setBrand(brandid));
	}, [brandid]);

	useEffect(() => {
		if (curFormat && curFormat.multiple) {
			setQty(qty_pack * curFormat.multiple);
		} else setQty(1);
	}, [curFormat, qty_pack, curFormat?.multiple]);

	useEffect(() => {
		if (formatsState && formatsState.length === 1) {
			setCurFormat(formatsState[0]);
			if (formatsState[0]) {
				navigate(
					{
						pathname: `/adstore/variants/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}${specificationid ? "/" + encodeSlash(specificationid) : ""}${
							"/" + encodeSlash(formatsState[0].erp_code)
						}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			}
		}
		if (formatsState && formatsState.length > 0 && formatid) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}${
						brandid ? "/" + encodeSlash(brandid) : ""
					}/${encodeSlash(specificationid)}/${encodeSlash(formatid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
			setCurFormat(formatsState.find((f) => f.erp_code === formatid));
		} else {
			setCurFormat(undefined);
		}
		if (specificationid && vSpecifications)
			setCurSpecification(vSpecifications.find((spec) => spec.title === specificationid));
		else setCurSpecification(undefined);
	}, [formatsState, specificationid, formatid]);

	useEffect(() => {
		if (isLoggedIn && curFormat && curFormat.sales_prices && curFormat.sales_prices.length > 0) {
			let prices = curFormat.sales_prices?.filter((sales_price) => sales_price.qty <= qty);
			prices?.sort((a, b) => {
				if (Number(a.qty) < Number(b.qty)) return 1;
				if (Number(a.qty) > Number(b.qty)) return -1;
				return 0;
			});
			setCustomerPrices(prices);
		} else setCustomerPrices([]);
	}, [isLoggedIn, curFormat, qty]);

	useEffect(() => {
		if (curFormat) {
			const leadtime = curFormat?.leadTime > 0 ? curFormat?.leadTime : 10;
			setShippingRange(`${t(keys.PRODUCTS.VARIANT.AVAILBLE1)} ${leadtime} ${t(keys.PRODUCTS.VARIANT.AVAILBLE2)}`);
		}
	}, [t, lngId, curFormat, isLoggedIn, requestQuoteHandler]);

	useEffect(() => {
		setQty_pack(
			curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
				? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
				: 1
		);
	}, [curFormat, curFormat?.multiple, curFormat?.min_qty]);

	useEffect(() => {
		if (curFormat) {
			let generiquePrice = curFormat.price;
			if (generiquePrice) generiquePrice = Number(generiquePrice).toFixed(2);
			else generiquePrice = 0;
			if (customerPrices && customerPrices.length > 0) {
				const customPrice = customerPrices.find(
					(customerPrice) =>
						((generiquePrice && Number(customerPrice.price) < generiquePrice) ||
							Number(customerPrice.price) > 0) &&
						Number(customerPrice.qty) <= Number(qty)
				);
				if (customPrice?.price) {
					setCurPrice(customPrice?.price);
					setUseCustomPrice(true);
				} else {
					setCurPrice(generiquePrice);
					setUseCustomPrice(false);
				}
			} else {
				setCurPrice(generiquePrice);
				setUseCustomPrice(false);
			}
		}
	}, [customerPrices, customerPrices.length]);

	useEffect(() => {
		if (curFormat) {
			switch (curFormat.packaging) {
				case 1:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BAG));
					setShowFormat(false);
					break;
				case 2:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BARREL));
					setShowFormat(false);
					break;
				case 3:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BOX));
					setShowFormat(true);
					break;
				case 4:
					setPackagingFormat(t(keys.GLOBAL.COMMON.EACH));
					setShowFormat(false);
					break;
				case 5:
					setPackagingFormat(t(keys.GLOBAL.COMMON.CANE));
					setShowFormat(false);
					break;
				case 6:
					setPackagingFormat(t(keys.GLOBAL.COMMON.DRUM));
					setShowFormat(false);
					break;
				case 7:
					setPackagingFormat(t(keys.GLOBAL.COMMON.PAIL));
					setShowFormat(false);
					break;
				case 8:
					setPackagingFormat(t(keys.GLOBAL.COMMON.TOTE));
					setShowFormat(false);
					break;
				default:
					setPackagingFormat(t(keys.GLOBAL.COMMON.BOX));
					setShowFormat(true);
					break;
			}
		}
	}, [t, lngId, curFormat]);

	const changeQtyHandler = (event) => {
		if (event.target.value) {
			setQty_pack(parseInt(event.target.value));
		} else {
			setQty_pack(1);
		}
	};

	const formatChangeHandler = (format) => {
		if (format) {
			setCurFormat(format);
			if (!formatid || formatid !== format.erp_code) {
				navigate(
					{
						pathname: `/adstore/variants/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}/${encodeSlash(specificationid)}/${encodeSlash(format.erp_code)}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			} else {
				navigate(
					{
						pathname: `/adstore/variants/${encodeSlash(itemid)}${
							brandid ? "/" + encodeSlash(brandid) : ""
						}/${encodeSlash(specificationid)}/`,
						search: queryParams.toString(),
					},
					{ replace: true }
				);
			}
		} else {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}${
						brandid ? "/" + encodeSlash(brandid) : ""
					}/${encodeSlash(specificationid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	};

	const specificationChangeHandler = (spec) => {
		if (specificationid && specificationid === spec.title) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}${brandid ? "/" + encodeSlash(brandid) : ""}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		} else {
			setCurSpecification(spec);
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}${
						brandid ? "/" + encodeSlash(brandid) : ""
					}/${encodeSlash(spec.title)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	};

	const onBlurQtyHandler = (event) => {
		if (
			curFormat &&
			event.target.value <
				(curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1)
		) {
			setShakeError(true);
			setQty_pack(
				curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1
			);
			setTimeout(() => {
				setShakeError(false);
			}, 350);
		}
	};

	const addQty = () => {
		setQty_pack(qty_pack + 1);
	};

	const minusQty = () => {
		if (
			curFormat &&
			qty_pack - 1 <
				(curFormat?.min_qty > 0 && curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1)
		) {
			setShakeError(true);
			setTimeout(() => {
				setShakeError(false);
			}, 350);

			return;
		}
		if (qty_pack > 1) setQty_pack(qty_pack - 1);
	};
	const addToCartHandler = () => {
		if (isLoggedIn) {
			if (curFormat) {
				const line = lines && lines.find((l) => l.variant.id === curFormat.variant_id);

				if (line) {
					dispatch(
						updateLine({
							id: line.id,
							variant_id: line.variant.id,
							qty: Number(qty) + Number(line.qty),
							qty_pack: Number(qty_pack) + Number(line.qty_pack),
							unitAmount: Number(curPrice),
							amount: ((Number(qty) + Number(line.qty)) * Number(curPrice)).toFixed(2),
							cartOpen: true,
						})
					);
				} else {
					dispatch(
						addLine({
							variant_id: curFormat.variant_id,
							qty: Number(qty),
							qty_pack: Number(qty_pack),
							unitAmount: Number(curPrice),
							amount: (Number(qty) * Number(curPrice)).toFixed(2),
						})
					);
				}
			}
		} else {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	};

	const closeSealantCalculator = () => {
		setSealantCalculaterOpen(false);
	};

	const handleStandard = () => {
		setvSpecificationRend(true);
		setvSpecifications(specificationsStock);
	};
	const handleRequest = () => {
		setvSpecificationRend(false);
		setvSpecifications(specificationsNonStock);
	};

	const addFavoriteHandler = (id) => {
		if (!isLoggedIn) return;
		if (favoriteSpecifications && favoriteSpecifications.includes(id)) {
			dispatch(deleteSpecificationFavorite(id));
		} else {
			dispatch(addSpecificationFavorite(id));
		}
	};

	const favoriteChangeHandler = (checked) => {
		dispatch(setFavorite(checked));
		if (checked === true) {
			queryParams.set("favorite", checked);
		} else {
			queryParams.delete("favorite");
		}
		navigate(
			{
				pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/`,
				search: queryParams.toString(),
			},
			{ replace: true }
		);
		setIsSubmited(true);
	};

	const searchChangeHandler = (searchValue) => {
		dispatch(setSearch(searchValue));
		/**
		 * enlever le parametre si la valeur du fitre est vide
		 */
		if (searchValue !== "") {
			queryParams.set("searchColor", searchValue);
		} else {
			queryParams.delete("searchColor");
		}
		/**
		 * modifier les parametres du lien au changement du filtre
		 * remplacer l'historique
		 */
		if (oldSearch === undefined || oldSearch === "" || isSubmited === true) {
			setIsSubmited(false);
			navigate({
				pathname: `/adstore/variants/${itemid}/${brandid}/`,
				search: queryParams.toString(),
			});
		} else {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
		setOldSearch(searchValue);
	};

	const eraseHandler = () => {
		searchChangeHandler("");
		setIsSubmited(true);
	};

	const submitHandler = (event) => {
		event.preventDefault();
		setIsSubmited(true);
	};

	return (
		<div ref={gridRef} className={classes.section}>
			{sealantCalculaterOpen && (
				<SealantCalculatorModal
					close={() => {
						closeSealantCalculator();
					}}
				/>
			)}

			<div className={`${classes.productInfoSection} ${props.className || ""}`}>
				<div className={classes.productInfoContainer}>
					<div className={classes.layout}>
						<ToggleSwitch
							label={t(keys.PRODUCTS.SHOW_ONLY_FAVORITE)}
							className={classes.toggle}
							labelClassName={classes.labelClassName}
							checked={favoriteState}
							onChange={favoriteChangeHandler}
							onColor="#86d3ff"
							onHandleColor="#2693e6"
							handleDiameter={25}
							uncheckedIcon={false}
							checkedIcon={false}
							disabled={variantLoading}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
						/>
						<button className={classes.resetBtn} onClick={eraseHandler}>
							{t(keys.PRODUCTS.OVERVIEW.RESETALL)}
						</button>
						<h3>1- {t(keys.PRODUCTS.OVERVIEW.SELECT_OPTION)}</h3>
						<div className={classes.search}>
							<SearchBar
								ref={searchRef}
								id="search_variant"
								placeholder={t(keys.PRODUCTS.SEARCH_COLOR_NUMBER)}
								value={searchState ? searchState : ""}
								onChange={(value) => searchChangeHandler(value)}
								onClick={eraseHandler}
								onSubmit={submitHandler}
								disabled={variantLoading}
							/>
						</div>
						{isVariantloaded && vSpecifications && vSpecifications.length > 0 && (
							<Fragment>
								<CustomNavLink
									className={classes.element}
									to={`/tools/find-the-right-sealant/${search}`}
									target={"_self"}
								>
									<div className={classes.text}>
										<p>{t(keys.TOOLS.SEALANTCALCULATOR.COLOR_MATCH)}</p>
									</div>
									<img src={colorMatching} alt="tube.svg" />
								</CustomNavLink>
								<div
									className={
										curSpecification
											? `${classes.containerMobile} ${classes.selectedContainer}`
											: classes.containerMobile
									}
								>
									{!curSpecification &&
										!variantLoading &&
										specificationsStock &&
										specificationsNonStock &&
										specificationsStock.length > 0 &&
										specificationsNonStock.length > 0 && (
											<div className={classes.buttons}>
												<Button
													className={`${classes.btn} ${
														!vSpecificationRend ? classes.disable : ""
													}`}
													onClick={handleStandard}
												>
													{t(keys.PRODUCTS.VARIANT.STANDARD)}
												</Button>
												<Button
													className={`${classes.btn} ${
														vSpecificationRend ? classes.disable : ""
													}`}
													onClick={handleRequest}
												>
													{t(keys.PRODUCTS.VARIANT.ON_REQUEST)}
												</Button>
											</div>
										)}
									<div className={classes.scrollableContainer}>
										{curSpecification ? (
											<Fragment>
												<div
													key={curSpecification.id}
													className={`${classes.colorCardContainer} ${classes.selected}`}
													onClick={() => specificationChangeHandler(curSpecification)}
												>
													<div className={classes.colorCardDetails}>
														{curSpecification.title && <p>{curSpecification.title}</p>}
														<p>
															{lngId.includes("fr")
																? curSpecification.subtitle_fr
																: curSpecification.subtitle_en}
														</p>
													</div>
													<div
														className={`${classes.colorCardImageDiv}`}
														style={{
															backgroundColor: curSpecification.hex ?? "#000000",
														}}
													/>
												</div>
											</Fragment>
										) : (
											vSpecifications.map((spec) => (
												<div
													key={spec.id}
													className={`${classes.colorCardContainer} ${
														specificationid && specificationid === spec.title
															? classes.selected
															: ""
													}`}
												>
													{isLoggedIn && (
														<Fragment>
															<Icon
																className={classes.favoriteOutlined}
																icon="ant-design:heart-outlined"
															/>
															<Icon
																className={`${classes.favoriteFilled} ${
																	classes[
																		favoriteSpecifications &&
																		favoriteSpecifications.includes(spec.id)
																			? "active"
																			: ""
																	]
																}`}
																icon="ant-design:heart-filled"
																onClick={() => addFavoriteHandler(spec.id)}
															/>
														</Fragment>
													)}
													<div
														className={classes.colorcard}
														onClick={() => specificationChangeHandler(spec)}
													>
														<div
															className={`${classes.colorCardDetails} ${
																isLoggedIn ? classes.colorCardDetailsLogedInd : ""
															}`}
														>
															{spec.title && <p>{spec.title}</p>}
															<p>
																{lngId.includes("fr")
																	? spec.subtitle_fr
																	: spec.subtitle_en}
															</p>
														</div>
														<div
															className={`${classes.colorCardImageDiv} ${
																isLoggedIn ? classes.colorCardImageDivLogedInd : ""
															}`}
															style={{ backgroundColor: spec.hex ?? "#000000" }}
														/>
													</div>
													{isLoggedIn && <div className={classes.colorFavorite} />}
												</div>
											))
										)}
										{!curSpecification && <div className={classes.rectangle}></div>}
									</div>
								</div>
								{formatsState &&
									(formatsState.length > 1 ||
										(formatsState.length === 1 &&
											formatsState[0].erp_code !== "n/a" &&
											formatsState[0].erp_code)) && (
										<h3>2- {t(keys.PRODUCTS.OVERVIEW.SELECT_FORMAT)}</h3>
									)}
								{formatsState &&
									(formatsState.length > 1 ||
										(formatsState.length === 1 &&
											formatsState[0].erp_code !== "n/a" &&
											formatsState[0].erp_code)) && (
										<Dropdown
											className={classes.inputJoint}
											onChange={(event) => {
												const selectedValue = event.target.value;
												const selectedFormat = formatsState.find(
													(format) => format.erp_code === selectedValue
												);
												formatChangeHandler(selectedFormat);
											}}
											wrapperClassName={classes.after}
											value={curFormat?.erp_code}
										>
											<option key="default" value={undefined}>
												{t(keys.PRODUCTS.OVERVIEW.SELECT_YOUR_FORMAT)}
											</option>
											{formatsState.map((format) => (
												<option key={format.erp_code} value={format.erp_code}>
													{format.erp_code}
												</option>
											))}
										</Dropdown>
									)}
								{curFormat &&
									formatsState &&
									(formatsState.length > 1 ||
										(formatsState.length === 1 &&
											formatsState[0].erp_code !== "n/a" &&
											formatsState[0].erp_code)) && (
										<h3>3- {t(keys.PRODUCTS.OVERVIEW.SELECT_QUANTITY)}</h3>
									)}
								<div className={classes.information}>
									<div className={classes.prices}>
										{curFormat && Number(curFormat.price) > 0 && useCustomPrice === false && (
											<p>
												<span>{t(keys.PRODUCTS.VARIANT.LIST_PRICE)}</span>
												<span
													className={
														customerPrices && customerPrices.length > 0
															? classes.disable
															: ""
													}
												>
													<span className={classes.price}>
														{lngId.includes("en") ? "$" : ""}
														{Number(curFormat?.price)
															.toFixed(2)
															.toString()
															.replace(
																/\B(?=(\d{3})+(?!\d))/g,
																lngId.includes("fr") ? " " : ","
															)
															.replace(".", lngId.includes("fr") ? "," : ".")}
														{lngId.includes("fr") ? "$" : ""}
													</span>
													<span className={classes.price}>
														/
														{(lngId.includes("fr")
															? curFormat?.description_fr
															: curFormat?.description_en
															? lngId.includes("fr")
																? curFormat?.description_fr
																: curFormat?.description_en
															: curFormat?.erp_code) ?? t(keys.GLOBAL.COMMON.UNIT)}
													</span>
													{showFormat && (
														<span>
															{" "}
															(
															{curFormat && curFormat?.multiple
																? curFormat?.multiple
																: 1}{" "}
															{(curFormat && curFormat?.multiple
																? curFormat?.multiple
																: 1) > 1
																? t(keys.GLOBAL.COMMON.UNITS)
																: t(keys.GLOBAL.COMMON.UNIT)}{" "}
															{t(keys.GLOBAL.COMMON.PER)} {packagingFormat})
														</span>
													)}
												</span>
											</p>
										)}
										{useCustomPrice === true && (
											<p>
												<span>{t(keys.PRODUCTS.VARIANT.YOUR_PRICE)}</span>
												<span
													className={
														customerPrices && customerPrices.length > 0
															? classes.disable
															: ""
													}
												>
													<span className={classes.price}>
														{lngId.includes("en") ? "$" : ""}
														{Number(curPrice)
															.toFixed(2)
															.toString()
															.replace(
																/\B(?=(\d{3})+(?!\d))/g,
																lngId.includes("fr") ? " " : "."
															)
															.replace(".", lngId.includes("fr") ? "," : ".")}
														{lngId.includes("fr") ? "$" : ""}
													</span>
													<span className={classes.price}>/{t(keys.GLOBAL.COMMON.UNIT)}</span>
													{showFormat && (
														<span>
															{" "}
															(
															{curFormat && curFormat?.multiple
																? curFormat?.multiple
																: 1}{" "}
															{(curFormat && curFormat?.multiple
																? curFormat?.multiple
																: 1) > 1
																? t(keys.GLOBAL.COMMON.UNITS)
																: t(keys.GLOBAL.COMMON.UNIT)}{" "}
															{t(keys.GLOBAL.COMMON.PER)} {packagingFormat})
														</span>
													)}
												</span>
											</p>
										)}
										<div className={classes.mobileMessages}>
											{curFormat && useCustomPrice === false && (
												<div className={classes.helper}>
													{isLoggedIn && !quoteRequested && (
														<p className={classes.link} onClick={requestQuoteHandler}>
															{t(keys.PRODUCTS.VARIANT.GET_QUOTE)}
														</p>
													)}
													{isLoggedIn && quoteRequested && (
														<p>{t(keys.PRODUCTS.VARIANT.QUOTE_SENT)}</p>
													)}
													{!isLoggedIn && (
														<CustomNavLink
															to={`/sign-in`}
															className={`${classes.link} ${classes.loginlink}`}
														>
															{t(keys.GLOBAL.COMMON.LOGIN_TO_SEE_PRICE)}
														</CustomNavLink>
													)}
												</div>
											)}
										</div>
										<div className={classes.warnings}>
											{(curFormat &&
												curFormat?.min_qty &&
												curFormat?.min_qty > curFormat?.multiple &&
												curFormat?.on_hand <= curFormat?.min_qty) === true && (
												<div
													className={`${classes.warning} ${
														shakeError === true ? classes.shakeError : ""
													}`}
												>
													<div className={classes.iconContainer}>
														<Icon className={classes.icon} icon="material-symbols:circle" />
													</div>
													<span>{`${t(keys.PRODUCTS.VARIANT.MIN_ORDER)} ${
														curFormat?.min_qty > 0 &&
														curFormat?.multiple &&
														curFormat?.multiple !== 0
															? (curFormat?.min_qty / curFormat?.multiple).toFixed(0)
															: curFormat?.min_qty
													}`}</span>
												</div>
											)}
											{curFormat && Number(curFormat.on_hand) / Number(curFormat.multiple) < 1 && (
												<div className={classes.warning}>
													<div className={classes.iconContainer}>
														<Icon className={classes.icon} icon="material-symbols:circle" />
													</div>
													<span>{shippingRange}</span>
												</div>
											)}
										</div>
										{curFormat && Number(curFormat.on_hand) / Number(curFormat.multiple) >= 1 && (
											<div className={classes.info}>
												<div className={classes.iconContainer}>
													<Icon className={classes.icon} icon="material-symbols:circle" />
												</div>
												<span>{`${
													Number(curFormat.multiple) === 0
														? 0
														: (
																Number(curFormat.on_hand) / Number(curFormat.multiple)
														  ).toFixed(0)
												} ${packagingFormat} ${t(keys.CART.OVERVIEW.AVAILABLE)}`}</span>
											</div>
										)}
									</div>
								</div>
							</Fragment>
						)}
					</div>

					{isVariantloaded && vSpecifications && vSpecifications.length > 0 && (
						<div className={classes.qty} ref={qtyRef}>
							{curFormat && (
								<div className={classes.numbers}>
									<div className={classes.center}>
										<div className={classes.row}>
											<div className={classes.qtyButtons}>
												<Button
													id="minus"
													className={classes.buttons}
													onClick={minusQty}
													color="outlineBlack"
													size="small"
													style={{ borderRight: "0" }}
												>
													-
												</Button>
												<TextInput
													id="value"
													className={classes.textInput}
													inputClassName={classes.input}
													onChange={changeQtyHandler}
													onBlur={onBlurQtyHandler}
													type="number"
													value={qty_pack}
													shakeError={shakeError}
												/>
												<Button
													id="add"
													className={classes.buttons}
													onClick={addQty}
													color="outlineBlack"
													size="small"
													style={{ borderLeft: "0" }}
												>
													+
												</Button>
											</div>
											{curFormat && (
												<h1>
													{`${
														(curFormat && curFormat.multiple ? curFormat?.multiple : 1) *
														qty_pack
													}
												${
													(curFormat && curFormat.multiple ? curFormat?.multiple : 1) *
														qty_pack >
													1
														? t(keys.GLOBAL.COMMON.UNITS)
														: t(keys.GLOBAL.COMMON.UNIT)
												}`}
												</h1>
											)}
										</div>
										<div className={classes.right}>
											<div
												className={classes.container}
												onClick={() => setSealantCalculaterOpen(true)}
											>
												<div className={classes.first}>
													<div className={classes.iconContainer}>
														<Icon
															className={classes.calculator}
															icon="bi:calculator-fill"
														/>
													</div>
													<h1>{t(keys.PRODUCTS.OVERVIEW.CALC_TOOL)}</h1>
												</div>
												<p>{t(keys.PRODUCTS.OVERVIEW.CALCULATOR)}</p>
											</div>
										</div>
									</div>
									<Button
										className={classes.addCartButton}
										onClick={addToCartHandler}
										disabled={isCartLoading || shakeError || curPrice <= 0}
									>
										{t(keys.PRODUCTS.OVERVIEW.ADD_TO_CART_BTN)}
									</Button>
								</div>
							)}
						</div>
					)}
					{isVariantloaded && vSpecifications.length == 0 && (
						<div className={classes.ColorNotFound}>
							<p>{t(keys.PRODUCTS.VARIANT.COLOR_NOT_FOUND)}</p>
							<p>1 800 567-2658</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default VariantInfo;
